import './App.css';
import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';
import logo from "./images/logo.png"

import slider from "./images/slider.png"
import slider2 from "./images/slider2.png"
import slider3 from "./images/slider3.png"
import slider4 from "./images/slider4.png"
import slider5 from "./images/slider5.png"
import slider6 from "./images/slider6.png"
import slider7 from "./images/slider7.png"
import slider8 from "./images/slider8.png"
import slider9 from "./images/slider9.png"
import slider10 from "./images/slider10.png"
import slider11 from "./images/slider11.png"
import slider12 from "./images/slider12.png"
import slider13 from "./images/slider13.png"
import slider14 from "./images/slider14.png"

import aboutSlider from "./images/pageSlides/aboutSlider.png"
import sectoralSlider from "./images/pageSlides/sectoralSlider.png"
import examsSlider from "./images/pageSlides/examsSlider.png"
import certSlider from "./images/pageSlides/certSlider.png"
import refSlider from "./images/pageSlides/refSlider.png"

import fb from "./images/socIcons/facebook.png"
import ig from "./images/socIcons/instagram.png"
import yt from "./images/socIcons/youtube.png"
import mail from "./images/socIcons/mail.png"

import univ1 from "./images/univLogos/univ1.png"
import univ2 from "./images/univLogos/univ2.png"
import univ3 from "./images/univLogos/univ3.png"
import univ4 from "./images/univLogos/univ4.png"
import univ5 from "./images/univLogos/univ5.png"
import univ6 from "./images/univLogos/univ6.png"
import univ7 from "./images/univLogos/univ7.png"
import univ8 from "./images/univLogos/univ8.png"

import hero1 from "./images/heroImages/hero1.jpg"
import hero2 from "./images/heroImages/hero2.jpg"
import hero3 from "./images/heroImages/hero3.jpg"
import hero4 from "./images/heroImages/hero4.jpg"
import hero5 from "./images/heroImages/hero5.jpg"

import lic1 from "./images/licenseLogos/lic1.png"
import lic2 from "./images/licenseLogos/lic2.png"
import lic3 from "./images/licenseLogos/lic3.png"

import acc1 from "./images/accrLogos/accr1.png"
import acc2 from "./images/accrLogos/accr2.png"
import acc3 from "./images/accrLogos/accr3.png"

import { useEffect, useState } from 'react';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
      <Routes>
        <Route element={<Home/>} path='/'></Route>
        <Route element={<About/>} path='/about'></Route>
        <Route element={<Sectoral/>} path='/sectoral'></Route>
        <Route element={<Exams/>} path='/exams'></Route>
        <Route element={<Certification/>} path='/certification'></Route>
        <Route element={<References/>} path='/references'></Route>
      </Routes>
      <Footer/>
      </div>
    </BrowserRouter>
    
  );
}

function Header(){
  let [isOpen, setIsOpen] = useState(false)
  function menuExpand(bool){
    if(isOpen){
      document.getElementById("MobileMenu").style.setProperty("display", "none")
      isOpen = !isOpen
      setIsOpen(isOpen)
    }else if(bool === false){
      document.getElementById("MobileMenu").style.setProperty("display", "none")
      isOpen = !isOpen
      setIsOpen(isOpen)
    }else{
      document.getElementById("MobileMenu").style.setProperty("display", "grid")
      isOpen = !isOpen
      setIsOpen(isOpen)
    }
  }
  return(
    <div id='Header'>
      <div style={{display:"flex", alignItems:"center"}}>
        <Link to="/"><img onClick={e => menuExpand(false)} src={logo} alt='logo'/></Link>
        <div style={{padding:"10px"}}>
          <h1>EuroPlus Group</h1>
          <h4>Your Success Story Starts Here</h4>
        </div>
      </div>
      
      <div id='Menu'>
        <Link to="/about">ΠΟΙΟΙ ΕΙΜΑΣΤΕ</Link>
        <Link to="/sectoral">ΟΙ ΤΟΜΕΑΣ ΕΚΠΑΙΔΕΥΣΕΙΣ ΜΑΣ</Link>
        <Link to="/exams">ΟΙ ΕΠΑΓΓΕΛΜΑΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ ΜΑΣ MYK</Link>
        <Link to="/certification">ΔΙΑΔΙΚΑΣΙΑ ΕΞΕΤΑΣΕΩΝ ΚΑΙ ΠΙΣΤΟΠΟΙΗΣΗΣ MYK/VQA</Link>
        <Link to="/references">ΟΙ ΑΝΑΦΟΡΕΣ ΜΑΣ</Link>
      </div>
      <div id='MenuButton'>
        <button onClick={menuExpand}>Menu</button>
      </div>
      <div id='MobileMenu'>
        <Link  onClick={menuExpand} to="/about">ΠΟΙΟΙ ΕΙΜΑΣΤΕ</Link>
        <Link  onClick={menuExpand} to="/sectoral">ΟΙ ΤΟΜΕΑΣ ΕΚΠΑΙΔΕΥΣΕΙΣ ΜΑΣ</Link>
        <Link  onClick={menuExpand} to="/exams">ΟΙ ΕΠΑΓΓΕΛΜΑΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ ΜΑΣ MYK</Link>
        <Link  onClick={menuExpand} to="/certification">ΔΙΑΔΙΚΑΣΙΑ ΕΞΕΤΑΣΕΩΝ ΚΑΙ ΠΙΣΤΟΠΟΙΗΣΗΣ MYK/VQA</Link>
        <Link  onClick={menuExpand} to="/references">ΟΙ ΑΝΑΦΟΡΕΣ ΜΑΣ</Link>
      </div>
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
      <div id='FooterLeft'>
        EuroPlus Group - 2022
      </div>
      <div id='FooterRight'>
        <a href="https://instagram.com/europlus.group?igshid=NGVhN2U2NjQ0Yg=="><img src={ig} alt="socialicons" /></a>
        <a href="mailto:group.europlus@gmail.com"><img src={mail} alt="socialicons" /></a>
        <a href="https://www.facebook.com/EuroplusGroupGreece?mibextid=ZbWKwL"><img src={fb} alt="socialicons" /></a>
        <a href="https://youtu.be/veWdcQz_6QQ?si=unrJwlTHrcJnKcDd"><img src={yt} alt="socialicons" /></a>
      </div>
    </div>
  )
}

function Home(){
  return(
    <div id='Home'>
      <Slider></Slider>
      <h1 style={{textAlign:"center", padding:"30px", display:"none"}}>Πανεπιστήμια με τα οποία έχουμε συμφωνίες</h1>
      <div style={{display:"none"}} id='UniversityLogos'>
        <a href="https://www.karatay.edu.tr/tr/"><img src={univ6} alt="univLogo"/></a>
        <a href="https://www.fsm.edu.tr/"><img src={univ1} alt="univLogo"/></a>
        <a href="https://altinbas.edu.tr/Pages/default.aspx"><img src={univ2} alt="univLogo"/></a>
        <a href="https://www.istanbularel.edu.tr/"><img src={univ3} alt="univLogo"/></a>
        <a href="https://www.sisli.edu.tr/"><img src={univ4} alt="univLogo"/></a>
        <a href="https://yuksekihtisasuniversitesi.edu.tr/"><img src={univ5} alt="univLogo"/></a>
        <a href="https://mudanya.edu.tr/"><img src={univ7} alt="univLogo"/></a>
        {/*<a href="https://rdu.edu.tr/"><img src={univ8} alt="univLogo"/></a>*/}
      </div>
      <div id='HeroImages'>
        <div id='HeroImagesCell'>
          <img src={hero1} alt="univLogo"/>
          <span>Κομμωτική-Άνδρικό</span>
        </div>
        <div id='HeroImagesCell'>
          <img src={hero2} alt="univLogo"/>
          <span>Αισθητικός με κερί</span>
        </div>
        <div id='HeroImagesCell'>
          <img src={hero3} alt="univLogo"/>
          <span>Μανικιούρ / Πεντικιούρ</span>
        </div>
        <div id='HeroImagesCell'>
          <img src={hero4} alt="univLogo"/>
          <span>Αισθητικός</span>
        </div>
        <div id='HeroImagesCell'>
          <img src={hero5} alt="univLogo"/>
          <span>Κομμωτική-Γυναικείο</span>
        </div>
      </div>
      <h1 style={{textAlign:"center", padding:"30px"}}>Οι άδειες επαγγελματικών προσόντων μας</h1>
      <div className='CertLogos'>
          <a href="https://iaf.nu/en/member-details/?member_id=100"><img src={lic2} alt="licLogo" style={{height:"125px"}}/></a>
          <a href="https://www.myk.gov.tr/"><img src={lic1} alt="licLogo" style={{height:"125px"}}/></a>
          <a href="https://europa.eu/europass/tr/contact-us/ulusal-europass-merkezleri"><img src={lic3} alt="licLogo" style={{height:"125px"}}/></a>
      </div>
      <h1 style={{textAlign:"center", padding:"30px"}}>Οι διεθνείς μας διαπιστεύσεις</h1>
      <div className='CertLogos'>
        <a href="https://iaf.nu/en/about-iaf-mla/"><img src={acc1} alt="accLogo" style={{height:"100px"}}/></a>
        <a href="https://nac-us.org/accreditation-activities-of-nac"><img src={acc2} alt="accLogo" style={{height:"100px"}}/></a>
        <a href="https://nac-us.org/about"><img src={acc3} alt="accLogo" style={{height:"100px"}}/></a>
      </div>
    </div>
  )
}

function About(){
  return(
    <div id="About">
      <img id='TopImage' src={aboutSlider} alt="slider2" />
      <div id='SectionContainer'>
      <h1>ΠΟΙΟΙ ΕΙΜΑΣΤΕ</h1>
      <span>Βρίσκεστε στο σωστό μέρος για να ανεβείτε σε υψηλότερα επίπεδα στην επαγγελματική σας ζωή,
      να επισημοποιήσετε την εμπειρία σας και να αποκτήσετε το Πιστοποιητικό Επαγγελματικής
      Ικανότητας MYK. Ως EuroplusGroup, είμαστε εδώ για να σας υποστηρίξουμε σε αυτό το σημαντικό
      βήμα!
      Το Πιστοποιητικό Επαγγελματικών Προσόντων MYK είναι ένα έγγραφο που αναγνωρίζεται και
      ισχύει όχι μόνο στην Τουρκία αλλά και διεθνώς. Με αυτό το έγγραφο, μπορείτε να επεκτείνετε την
      καριέρα σας σε μια ευρύτερη περιοχή και να έχετε την ευκαιρία να εργαστείτε διεθνώς.
      Επιπλέον, ως Όμιλος Europlus, προσφέρουμε την καλύτερη ποιότητα κλαδικών εκπαιδευτικών
      υπηρεσιών για την υποστήριξη της προσωπικής και επαγγελματικής εξέλιξης κάθε ατόμου στον
      κλάδο της ομορφιάς. Στόχος μας είναι να παρέχουμε στους μαθητές μας την ευκαιρία να

      ειδικευτούν στη βιομηχανία ομορφιάς μέσω προγραμμάτων επαγγελματικής κατάρτισης και να
      τους βοηθήσουμε να πετύχουν τους στόχους τους.</span>
      <h1>ΤΟ ΟΡΑΜΑ ΜΑΣ</h1>
      <span>Να διασφαλίζει τη συγκρότηση καταρτισμένου ανθρώπινου δυναμικού διασφαλίζοντας την επαγγελματική
      επάρκεια και να είναι ένας εθνικά και διεθνώς αναγνωρισμένος, αποτελεσματικός και σεβαστός θεσμός.
      Η EUROPLUS έχει υιοθετήσει το όραμα της εφαρμογής διεθνών προγραμμάτων επαγγελματικής κατάρτισης
      και πιστοποίησης σε ακαδημαϊκό επίπεδο, με ευθύνη της εκπαίδευσης επαγγελματιών στον κλάδο της
      ομορφιάς με το εξειδικευμένο εκπαιδευτικό προσωπικό της που έχει σύγχρονη νοοτροπία και μπορεί να
      αφομοιώσει τις τάσεις, τις καινοτομίες και τις τεχνολογικές εξελίξεις και να τις ενσωματώσει με τη ζωή.
      Η EUROPLUS έχει γίνει η κορυφαία εταιρία στον κλάδο της, με την εκπαίδευση που παρέχει.
      Ως ΟΜΙΛΟΣ EUROPLUS, δεσμευόμαστε να είμαστε οι καλύτεροι σε υπηρεσίες και ποιότητα. Συνεχίζουμε την
      υπόσχεσή μας να παρέχουμε ανώτερες υπηρεσίες και προϊόντα με προσπάθεια και επιμέλεια πάνω από τα
      πρότυπα.</span>
      <h1>Η ΑΠΟΣΤΟΛΗ ΜΑΣ</h1>
      <span>Ως Όμιλος Europlus, η αποστολή μας είναι: Να αυξήσουμε τον αριθμό των εκπαιδευμένων
      επαγγελματιών στον κλάδο και να εξασφαλίσουμε τη συνέχεια των εμπορικών σημάτων μας
      αυξάνοντας την επιτυχία και τη φήμη τους μέρα με τη μέρα. Να εκπροσωπεί περήφανα την
      παγκόσμια επιτυχία της με το εκπαιδευμένο και επαγγελματικό προσωπικό μας στην Ελλάδα και τις
      διεθνείς πλατφόρμες.
      Ο Όμιλος Europlus στοχεύει να εκπαιδεύσει το υψηλά καταρτισμένο ανθρώπινο δυναμικό που
      απαιτείται σε παρόμοιους τομείς εξυπηρέτησης, ιδιαίτερα στον τομέα της ομορφιάς της χώρας μας,
      και να εκπαιδεύσει επαγγελματίες που είναι εξοπλισμένοι με τις πιο σύγχρονες και προηγμένες
      τεχνολογικές ευκαιρίες, που έχουν υιοθετήσει ως αρχή την ικανοποίηση των πελατών και να
      καλύψει τις ανάγκες της χώρας μας σε ποιοτικό προσωπικό για την επίλυση προβλημάτων
      απασχόλησης. Έχει υιοθετήσει την αποστολή της παροχής διεθνώς διαπιστευμένων υπηρεσιών
      πιστοποίησης.
      Η εταιρία μας συνεργάζετε με τους πιο έμπειρους και επιτυχημένους εκπαιδευτές, προκειμένου να
      παρέχουμε σε εσάς, τους πελάτες μας, τις καλύτερες υπηρεσίες στη βιομηχανία ομορφιάς, με τις
      εκπαιδεύσεις μας προσαρμοσμένες σύμφωνα με τις ανάγκες σας και την αναγνωρισιμότητα της
      επωνυμίας μας.</span>
      <h1>Επικοινωνήστε μαζί μας</h1>
      <iframe width="100%" style={{justifySelf:"center", padding:"30px"}} height="300" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%CE%A0%CE%B1%CF%8D%CE%BB%CE%BF%CF%85%20%CE%9C%CE%B5%CE%BB%CE%AC%202%20%CE%94%CE%AC%CF%86%CE%BD%CE%B7,%20%CE%91%CE%B8%CE%AE%CE%BD%CE%B1+(EuroPlus%20Group)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
      <h1>EuroPlus Group</h1>
      <h2>Εργίνα Κυρίκου - Γενική Τεχνική Διευθύντρια</h2>
      <h2>GSM: +30 694 857 91 39</h2>
      </div>
      
    </div>
  )
}

function Sectoral(){
let [isOpen, setIsOpen] = useState([false,false,false,false])
let [expandText, setExpandText] = useState(["+","+","+","+"])
  async function handleExpand(number){
    if(isOpen[number-1]){
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `none`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "+"
      setExpandText([...expandText])
    }else{
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `grid`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "-"
      setExpandText([...expandText])
    }
  }

  return(
    <div id="Sectoral">
      <img id='TopImage' src={sectoralSlider} alt="slider2" />
      <div id='ButtonSectionContainer'>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(1)}>ΟΙ ΤΟΜΕΑΚΕΣ ΜΑΣ ΕΚΠΑΙΔΕΥΣΕΙΣ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[0]}</h3></h3>
        <span id='ExpandContainer1'>Λαμβάνοντας υπόψη τις ανταγωνιστικές συνθήκες που έχουμε φτάσει σήμερα, έχει γίνει πολύ
        δύσκολο για ένα άτομο να βρει μια θέση για τον εαυτό του στον επαγγελματικό τομέα χωρίς να
        βελτιώσει τον εαυτό του, να παρακολουθήσει πιστοποιημένες εκπαιδεύσεις και να κάνει τη
        διαφορά στον τομέα της βιομηχανίας ομορφιάς.
        Ως EUROPLUS, προσφέρουμε τις καλύτερες ποιοτικές υπηρεσίες εκπαίδευσης για να υποστηρίξουμε
        την προσωπική και επαγγελματική ανάπτυξη κάθε ατόμου στον κλάδο της ομορφιάς. Στόχος μας
        είναι να παρέχουμε στους ενήλικες την ευκαιρία να ειδικευτούν στη βιομηχανία ομορφιάς μέσω
        προγραμμάτων επαγγελματικής κατάρτισης και να τους βοηθήσουμε να πετύχουν τους στόχους
        τους.
        Αυτά τα προγράμματα έχουν σχεδιαστεί για να καλύπτουν τις ανάγκες οποιουδήποτε θέλει να
        μάθει και να αναπτύξει τρέχουσες δεξιότητες στη βιομηχανία ομορφιάς.
        Οι Τομεακές / Επιμορφωτικές Εκπαιδεύσεις μας είναι ένας από τους σημαντικότερους στόχους μας
        να δημιουργήσουμε ευαισθητοποίηση και διαφορά στην επαγγελματική και προσωπική σας εξέλιξη
        μέσω των τομεακών μαθημάτων κατάρτισης που παρέχουμε πρόσωπο με πρόσωπο. Σε αυτό το
        σύστημα, το οποίο μεταφέρει ίσες ευκαιρίες στην εκπαίδευση σε διεθνείς πλατφόρμες, η ποιότητα,
        το ποσοστό επιτυχίας και η αξιοπιστία της εκπαίδευσης βρίσκονται στην πρώτη γραμμή.
        Αυτές οι εκπαιδεύσεις προτιμώνται από πτυχιούχους που θα ξεκινήσουν την επιχειρηματική τους
        ζωή στον κλάδο, έμπειρα άτομα που θέλουν να μεταφέρουν τον σχεδιασμό της σταδιοδρομίας τους
        σε άλλες διαστάσεις ή ιδρύματα, οργανισμούς και ιδιωτικές εταιρείες που θέλουν να συμβάλουν
        στην ανάπτυξη των εργαζομένων τους, οι εκπαιδευόμενοι παίρνουν τον εαυτό τους και τα ιδρύματα
        ή τις εταιρείες που εκπροσωπούν, εάν υπάρχουν, ένα βήμα πιο πέρα από τα σύνορα της χώρας.</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(2)}>ΟΙ ΔΙΕΘΝΩΣ ΠΙΣΤΟΠΟΙΗΜΕΝΕΣ/ΔΙΑΠΙΣΤΕΥΜΕΝΕΣ ΕΚΠΑΙΔΕΥΣΕΙΣ ΜΑΣ<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[1]}</h3></h3>
        <span id='ExpandContainer2'>Με την εταιρική μας εμπειρία ,που αποκτήθηκε εδώ και πολλά χρόνια, προσφέρουμε σε όσους
        θέλουν να βελτιώσουν τον εαυτό τους στον τομέα της ομορφιάς για προσωπική και επαγγελματική
        εξέλιξη. Οργανώνουμε διεθνώς διαπιστευμένα, εγκεκριμένα από το ICCW προγράμματα κατάρτισης
        πιστοποιητικών που είναι υποστηρικτικά, προσόντα και έχουν υψηλές ευκαιρίες απασχόλησης.
        Προχωρήστε τον Σχεδιασμό Σταδιοδρομίας σας με το Διεθνές Πρόγραμμα Πιστοποιητικών!
        Σύμφωνα με τις διαδικασίες ISO του ICC World (Διεθνές Κέντρο Πιστοποίησης του Κόσμου) και τα
        πρότυπα ASTM 2659-18, οι Εκπαιδεύσεις Προσωπικής Ανάπτυξης είναι πιστοποιητικά που μπορούν
        να αναζητηθούν μέσω Barcode και QR code.
        Τα πιστοποιητικά σας ισχύουν σε όλο τον κόσμο με διεθνή κωδικό επαλήθευσης. Έτσι, σας
        διευκολύνει να βρείτε δουλειά στην Ευρώπη και σε πολλές άλλες χώρες. Σας επιτρέπει να
        αποδείξετε ότι τα πιστοποιητικά σας πληρούν τα πρότυπα που είναι αποδεκτά σε όλο τον κόσμο.

        Μπορείτε επίσης να επικοινωνήσετε μαζί μας για να μεταφέρετε την εκπαίδευση που λάβατε στο
        εσωτερικό σε διεθνείς πλατφόρμες και να διαμορφώσετε την καριέρα σας.
        ΕΠΑΛΗΘΕΥΣΗ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ με BARCODE και QR CODE:
        Τα πιστοποιητικά μπορούν να επαληθευτούν με το barcode στα νέα δελτία ταυτότητας από την
        εφαρμογή κινητού τηλεφώνου ICCW. Με αυτόν τον τρόπο, ο κάτοχος του πιστοποιητικού μπορεί να
        επαληθεύσει και να δει τα έγγραφά του από το κινητό του τηλέφωνο.</span>
        <h3 style={{display:"none", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(3)}>ΕΓΚΕΚΡΙΜΕΝΕΣ ΕΚΠΑΙΔΕΥΣΕΙΣ ΤΟΥ ΠΑΝΕΠΙΣΤΗΜΙΟΥ ΜΑΣ<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[2]}</h3></h3>
      <span style={{display:"none"}} id='ExpandContainer3'>Εάν έχετε εμπειρία στο επάγγελμά σας, εργάζεστε στο αρμόδιο τμήμα για πολλά χρόνια, αλλά δεν
        έχετε καμία βεβαίωση για το επάγγελμά σας, είναι ένα πιστοποιητικό που μπορείτε να έχετε
        σύμφωνα με μια συγκεκριμένη διαδικασία ως αποτέλεσμα της αίτησής σας για διαπιστευμένο
        έγγραφο. Όλα τα έγγραφα που λαμβάνετε θα σας παραδοθούν με την υπογραφή του πρύτανη του
        πανεπιστημίου.
        Αποδεικνύετε την επαγγελματική σας επάρκεια με τα έγγραφα που δικαιούστε να λαμβάνετε από
        πιστοποιημένα από το πανεπιστήμιο προγράμματα κατάρτισης. Αυτό το έγγραφο δείχνει την πείρα
        σας στις προσλήψεις.
        1.Ισχύει διεθνώς στο επάγγελμα του κομμωτή. Πιστοποιητικό επαγγελματικής κατάρτισης
        Journeyman, Master, Master Instructor
        2. Ισχύει στο επάγγελμα του Beauty Specialist. Journeyman, Master, Master Instructor,
        πιστοποιητικό επαγγελματικής κατάρτισης</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(4)}>EKΠΑΙΔΕΥΤΙΚΗ ΠΡΟΕΤΟΙΜΑΣΙΑ/ΕΠΙΜΟΡΦΩΣΗ ΓΙΑ ΤΗΝ ΕΠΙΤΥΧΙΑ ΤΩΝ ΕΞΕΤΑΣΕΩΝ
        ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ VQA/MYK (ΔΙΕΘΝΗ ΕΠΑΓΓΕΛΜΑΤΙΚΗ ΕΠΑΡΚΕΙΑ)<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[3]}</h3></h3>
        <span id='ExpandContainer4'>Το Πιστοποιητικό Επαγγελματικής Ικανότητας είναι σημαντικό γιατί είναι επίσημο έγγραφο που
        δηλώνει ότι το άτομο που θέλει να ασκήσει ένα επάγγελμα έχει επαρκείς γνώσεις, δεξιότητες και
        ικανότητες.

        Για να εγγραφείτε στην προετοιμασία μας για τις Εξετάσεις Eπαγγελματικής Επάρκειας, δεν
        υπάρχουν απαιτήσεις αποφοίτησης ή περίοδος ασφάλισης απασχόλησης και οποιοσδήποτε άνω
        των 18 ετών μπορεί να υποβάλει αίτηση για την εκπαίδευσή μας και τη συμμετοχή στις εξετάσεις
        επάρκειας. Εγγραφές υποψηφίων που δεν έχουν προκαταρκτική αίτηση δεν γίνονται δεκτές.
        Μπορείτε να προεγγραφείτε μέσω της ιστοσελίδας μας ή καλώντας στο +30 694 857 9139 .
        Μπορείτε να κάνετε την πληρωμή με πιστωτική κάρτα, τραπεζικό λογαριασμό ή μετρητά. Αιτήσεις
        χωρίς πληρωμή δεν θα γίνονται δεκτές.
        ΣΗΜΑΝΤΙΚΗ ΣΗΜΕΙΩΣΗ: ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΠΟΥ ΠΛΗΡΩΘΟΥΝ ΑΠΟ ΤΟΥΣ ΑΙΤΟΥΝΤΕΣ ΔΕΝ ΜΠΟΡΟΥΝ
        ΝΑ ΕΠΙΣΤΡΕΦΟΝΤΑΙ ΓΙΑ ΚΑΝΕΝΑΝ ΛΟΓΟ! ΠΑΡΑΚΑΛΩ ΔΙΑΒΑΣΤΕ ΠΡΟΣΕΚΤΙΚΑ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΠΡΙΝ
        ΚΑΝΕΤΕ ΤΗΝ ΑΙΤΗΣΗ ΣΑΣ ΚΑΙ ΜΗΝ ΖΗΤΗΣΕΤΕ ΕΠΙΣΤΡΟΦΗ ΧΡΗΜΑΤΩΝ!
        Μετά την ολοκλήρωση της απαραίτητης διαδικασίας προετοιμασίας, όσοι συμμετέχουν στο
        πρόγραμμα θα αξιολογηθούν δίνοντας θεωρητικές εξετάσεις και εξετάσεις επίδοσης την επόμενη
        ημερομηνία που καθορίζεται περιοδικά από την Αρχή Επαγγελματικών Προσόντων της ΜΥΚ. Εάν
        επιτύχουν ως αποτέλεσμα των εξετάσεων Θεωρητικών και Επιδόσεων, θα δικαιούνται να λάβουν
        ένα διεθνώς έγκυρο έγγραφο εγκεκριμένο από την Αρχή Επαγγελματικών Προσόντων MYK ( με
        εγγραφή των διεθνών επαγγελματικών προσόντων στο μητρώο της MYK ).
        Εξετάσεις επαγγελματικού κλάδου σε προσόντα του τομέα κοινωνικών και προσωπικών υπηρεσιών
        Εξετάσεις επάρκειας στον Τομέα Κοινωνικών και Προσωπικών Υπηρεσιών.
        <br />
        <br />1) Αισθητικός αποτρίχωσης με κερί (Επίπεδο 2 / ΕQF )
        <br />2) Τεχνίτης περιποίησης νυχιών/Προσθετικής νυχιών (Επίπεδο 3 / EQF )
        <br />3) Επαγγελματίας μασάζ (Επίπεδο 3 / EQF)
        <br />4) Μανικιούρ /Πεντικιούρ (Επίπεδο 3 / EQF)
        <br />5) Περιφεριακό αδυνάτισμα (Επίπεδο 3 / EQF)
        <br />6) Aισθητικός μακιγιάζ (Επίπεδο 3 / EQF)
        <br />7) Αισθητικός μακιγιέρ (Επίπεδο 5 / EQF)
        <br />8) Επαγγελματίας μόνιμου μακιγιάζ (Επίπεδο 3 / EQF)
        <br />9) Επαγγελματίας περιποίησης δέρματος (Επίπεδο 3 / EQF )
        <br />10) Προσωπικό προώθησης καλλυντικών προϊόντων (Επίπεδο 3 / EQF )
        <br />11) Ειδικός αποτρίχωσης και ηλεκτρόλυσης IPL (Επίπεδο 4 / ΕQF )
        <br />12) Κομμωτική-Γυναικείο (Επίπεδο 4 / ΕQF )
        <br />13) Κομμωτική-Άνδρικό (Επίπεδο 4 / ΕQF )
        <br />14) Αισθητικός (Επίπεδο 4 / ΕQF )</span>
      </div>
    </div>
  )
}

function Exams(){
  let [isOpen, setIsOpen] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false])
  let [expandText, setExpandText] = useState(["+","+","+","+","+","+","+","+","+","+","+","+","+","+"])
  async function handleExpand(number){
    if(isOpen[number-1]){
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `none`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "+"
      setExpandText([...expandText])
    }else{
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `grid`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "-"
      setExpandText([...expandText])
    }
  }
  return(
    <div id="Exams">
      <img id='TopImage' src={examsSlider} alt="slider2" />
      <div id='SectionContainer'>
        <h1>ΟΙ ΕΞΕΤΑΣΕΙΣ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK/VQA</h1>
        <span>Για να αποκτήσετε αυτό το πιστοποιητικό, πρέπει να είστε γνώστες αυτού του τομέα και να
        αποδείξετε τις γνώσεις σας με τη θεωρητική και πρακτική εξέταση.
        Η διαδικασία των Επαγγελματικών Προσόντων είναι εξ ολοκλήρου μια διαδικασία μέτρησης και
        αξιολόγησης. Το άτομο υποβάλλεται σε 2 εξετάσεις που σχετίζονται με την εργασία του. Αν πάρει
        έγκυρο βαθμό από αυτές τις εξετάσεις, εκ των οποίων η μία είναι ΘΕΩΡΗΤΙΚΗ και η άλλη εξέταση
        ΠΡΑΚΤΙΚΗΣ ΑΠΟΔΟΣΗΣ για αξιολόγηση . Το πιστοποιητικό επαγγελματικών προσόντων αποδίδεται
        σε αυτούς που ολοκληρώνουν επιτυχώς τις θεωρητικές/πρακτικές εξετάσεις.</span>
      </div>
      <div id='ButtonSectionContainer'>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(1)}>Αισθητικός αποτρίχωσης με κερί (Επίπεδο 2 / ΕQF )<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[0]}</h3></h3>
      <span id='ExpandContainer1'>Άτομο που έχει τις γνώσεις και τις δεξιότητες να πραγματοποιήσει τη διαδικασία αποτρίχωσης και
τις σχέσεις με τους πελάτες σύμφωνα με τους κανονισμούς της νομοθεσίας OHS( υγιεινή και
ασφάλεια στην εργασία) τους περιβαλλοντικούς και υγειονομικούς κανόνες.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(2)}>Τεχνίτης περιποίησης νυχιών/Προσθετικής νυχιών (Επίπεδο 3 / EQF )<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[1]}</h3></h3>
      <span id='ExpandContainer2'>Είναι καταρτισμένο άτομο που οργανώνει εργασίες περιποίησης νυχιών σχετικές με το επάγγελμά
του, εκτελεί δραστηριότητες εφαρμογής περιποιήσεις άκρων (καθαρισμό επωνυχίων / υγρό
/σχηματισμός φυσικού νυχιού κτλ). Εκτελεί υψηλές υπηρεσίες προσθετικών νυχιών χεριών /
ποδιών και συμμετέχει σε δραστηριότητες επαγγελματικής ανάπτυξης, στο πλαίσιο των απαιτήσεων
ποιότητας, λαμβάνοντας υπόψη την υγεία και ασφάλεια στην εργασία και την προστασία του
περιβάλλοντος μέτρα.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(3)}>Επαγγελματίας μασάζ (Επίπεδο 3 / EQF)<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[2]}</h3></h3>
      <span id='ExpandContainer3'>Είναι καταρτισμένο άτομο που οργανώνει εργασίες σύμφωνα με τις απαιτήσεις υγιεινής και
ασφάλειας στην εργασία, περιβαλλοντικής προστασίας και ποιότητας, καθορίζει τη διαδικασία που
πρέπει να εκτελεστεί λαμβάνοντας αιτήματα πελατών, εκτελεί μασάζ χρησιμοποιώντας κλασικές
τεχνικές μασάζ ( τριβή, πίεση, κρουστά, δόνηση κτλ.) και πραγματοποιεί δραστηριότητες
επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(4)}>Μανικιούρ /Πεντικιούρ (Επίπεδο 3 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[3]}</h3></h3>
      <span id='ExpandContainer4'>Είναι καταρτισμένο άτομο που οργανώνει εργασίες σχετικές με το επάγγελμά του, κάνει μανικιούρ,
πεντικιούρ, προσθετική εφαρμογή νυχιών, αποτρίχωση κάλων και εσώρουχων τριχών και
συμμετέχει σε δραστηριότητες επαγγελματικής ανάπτυξης, στο πλαίσιο των απαιτήσεων ποιότητας,
από λήψη μέτρων για την υγεία και ασφάλεια στην εργασία και την προστασία του περιβάλλοντος.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(5)}>Αισθητικός για τοπικό αδυνάτισμα (Επίπεδο 3 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[4]}</h3></h3>
      <span id='ExpandContainer5'>Είναι καταρτισμένο άτομο που κινητοποιεί το τοπικό σωματικό λίπος επεμβαίνοντας με χέρια ή
συσκευές σύμφωνα με τις ποιοτικές απαιτήσεις, διεξάγει περιφερειακές διαδικασίες ανάκτησης και
συμμετέχει σε δραστηριότητες επαγγελματικής ανάπτυξης, λαμβάνοντας μέτρα για την υγεία και
ασφάλεια της εργασίας και την προστασία του περιβάλλοντος.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(6)}>Aισθητικός μακιγιάζ (Επίπεδο 3 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[5]}</h3></h3>
      <span id='ExpandContainer6'>Οργάνωση εργασιών στο πλαίσιο των ποιοτικών απαιτήσεων, συμμόρφωση με μέτρα υγιεινής και
ασφάλειας στην εργασία και προστασίας του περιβάλλοντος, προσδιορισμός του τύπου μακιγιάζ
και καλλυντικών που θα χρησιμοποιηθούν, προετοιμασία του δέρματος για μακιγιάζ, εφαρμογή
βασικού μακιγιάζ, μακιγιάζ φρυδιών , των ματιών , τα μάγουλα και χειλιών εντός του πεδίου
εφαρμογής των τυπικών εφαρμογών μακιγιάζ, εφαρμογή προσθετικών βλεφαρίδων ,είναι
καταρτισμένο άτομο που συμμετέχει σε δραστηριότητες επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(7)}>Αισθητικός μακιγιέρ (Επίπεδο 5 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[6]}</h3></h3>
      <span id='ExpandContainer7'>Οργάνωση εργασιών στο πλαίσιο των απαιτήσεων ποιότητας με τη συμμόρφωση με τα μέτρα
προστασίας της υγείας και ασφάλειας στην εργασία και την προστασία του περιβάλλοντος, την
εφαρμογή εννοιολογικών/θεματικών τεχνικών μακιγιάζ και προηγμένων τεχνικών μακιγιάζ με τον
προσδιορισμό του τύπου του μακιγιάζ και των καλλυντικών που θα χρησιμοποιηθούν, την
προετοιμασία καλούπια προσώπου και μάσκες προσώπου, απλώνοντας γένια / μουστάκι και
περούκες, Είναι εξειδικευμένο άτομο που αφαιρεί καλούπια μοντελισμού περούκας , κάνει μακιγιάζ

σώματος, κάνει εφέ μακιγιάζ και κάνει μακιγιάζ με ειδικές τεχνικές και συμμετέχει σε
δραστηριότητες επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(8)}>Επαγγελματίας μόνιμου μακιγιάζ (Επίπεδο 3 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[7]}</h3></h3>
      <span id='ExpandContainer8'>Λήψη μέτρων υγιεινής και ασφάλειας στην εργασία και προστασίας του περιβάλλοντος στο πλαίσιο
των απαιτήσεων ποιότητας, οργάνωση εργασιών, καθορισμός και απολύμανση του σημείου του
δέρματος του πελάτη που θα εφαρμοστεί το μόνιμο μακιγιάζ, προετοιμασία του μείγματος βαφής,
προετοιμασία της συσκευής μόνιμου μακιγιάζ, εφαρμογή μόνιμου μακιγιάζ με διαφορετικές
τεχνικές στις περιοχές των φρυδιών, των ματιών και των χειλιών και συμμετέχει σε δραστηριότητες
επαγγελματικής ανάπτυξης .Eίναι καταρτισμένο άτομο.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(9)}>Επαγγελματίας περιποίησης δέρματος (Επίπεδο 3 / EQF )<h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[8]}</h3></h3>
      <span id='ExpandContainer9'>Είναι εξειδικευμένο άτομο που καθαρίζει, απολυμαίνει και επιδιορθώνει το δέρμα σύμφωνα με
τους κανόνες υγιεινής και ποιότητας λαμβάνοντας προφυλάξεις σχετικά με την υγεία και ασφάλεια
στην εργασία και την προστασία του περιβάλλοντος και συμμετέχει σε δραστηριότητες
επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(10)}>Προσωπικό Προώθησης και Εφαρμογής Καλλυντικών Προϊόντων (Επίπεδο 3 / EQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[9]}</h3></h3>
      <span id='ExpandContainer10'>Είναι εξειδικευμένο άτομο που λαμβάνει μέτρα για την υγεία και ασφάλεια στην εργασία και την
προστασία του περιβάλλοντος, εφαρμόζει τις απαιτούμενες διαδικασίες για την προώθηση
καλλυντικών προϊόντων σύμφωνα με τις απαιτήσεις ποιότητας και συμμετέχει σε δραστηριότητες
επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(11)}>Ειδικός αποτρίχωσης και ηλεκτρόλυσης IPL (Επίπεδο 4 / ΕQF ) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[10]}</h3></h3>
      <span id='ExpandContainer11'>Είναι εξειδικευμένο άτομο που λαμβάνει προφυλάξεις σχετικά με την υγεία και ασφάλεια στην
εργασία και το περιβάλλον, κάνει προετοιμασίες πριν από την εφαρμογή σύμφωνα με τους κανόνες
υγιεινής, υγιεινής και ποιότητας, πραγματοποιεί εφαρμογές αποτρίχωσης ηλεκτρονικών και
ελαφρών συστημάτων και συμμετέχει σε δραστηριότητες επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(12)}>Κομμωτική- Γυναικείo (Επίπεδο 4 / ΕQF ) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[11]}</h3></h3>
      <span id='ExpandContainer12'>Άτομο εξειδικευμένο που οργανώνει εργασίες σύμφωνα με την επαγγελματική υγεία και ασφάλεια,
την προστασία του περιβάλλοντος και τις απαιτήσεις ποιότητας, προετοιμάζει τους πελάτες σχετικά
με τις διαδικασίες που θα εκτελεστούν λαμβάνοντας υπόψιν τα αιτήματα τους, κάνει κούρεμα και
περιποίηση, χρησιμοποιεί ντεκαπάζ και χρωστικά για αλλαγή χρώματος μαλλιών. Προετοιμάζει το
μείγμα καλλυντικών προϊόντων και το εφαρμόζει στα μαλλιά, εφαρμόζει ανταύγειες/ μπαλαγιάζ στα
μαλλιά, εφαρμόζει προστατευτικά καλλυντικά για μόνιμο styling μαλλιών, εφαρμόζει θεραπείες
ημιμόνιμες και μόνιμες όπως κερατίνη στα μαλλιά κτλ, προσθετική μαλλιών με διάφορες τεχνικές,
προσωρινό styling στα μαλλιά .Είναι καταρτισμένο άτομο που κάνει πιστολάκι, εφαρμόζει μιζαμπλί
και βραδινά χτενίσματα και πραγματοποιεί δραστηριότητες πελατειακών σχέσεων και
επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(13)}>Κομμωτική-Ανδρικό (Επίπεδο 4 / ΕQF) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[12]}</h3></h3>
      <span id='ExpandContainer13'>Άτομο εξειδικευμένο που οργανώνει εργασίες σύμφωνα με την επαγγελματική υγεία και ασφάλεια,
την προστασία του περιβάλλοντος και τις απαιτήσεις ποιότητας, προετοιμάζει τους πελάτες σχετικά
με τις διαδικασίες που θα εκτελεστούν λαμβάνοντας υπόψιν τα αιτήματα τους, ξύρισμα/ κούρεμα
και φροντίδα μαλλιών, περιποιείται τα γένια / μουστάκι και χρησιμοποιεί μόνιμες βαφές μαλλιών.
Ετοιμάζει το μείγμα καλλυντικών προϊόντων που ανοίγει/αλλάζει χρώμα και το εφαρμόζει στα

μαλλιά ,εφαρμόζει ανταύγειες/πλέγμα/μπαλαγιάζ στα μαλλιά, εφαρμόζει προστατευτικά
καλλυντικά προϊόντα για μόνιμο styling στα μαλλιά, καθορίζει το προϊόν μόνιμης styling και η
τεχνική χρήσης του, κάνει μόνιμο ίσιωμα, εφαρμόζει κερατίνη στα μαλλιά. Είναι εξειδικευμένο
άτομο που κάνει στέγνωμα με πιστολάκι, επέκταση/προσθετική μαλλιών για σκοπούς styling και
πραγματοποιεί δραστηριότητες πελατειακών σχέσεων και επαγγελματικής ανάπτυξης.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(14)}>Αισθητικός (Επίπεδο 4 / ΕQF ) <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[13]}</h3></h3>
      <span id='ExpandContainer14'>Άτομο το οποίο οργανώνει την εργασία του σύμφωνα με την επαγγελματική υγεία και ασφάλεια,
την προστασία του περιβάλλοντος και τις απαιτήσεις ποιότητας, καθορίζει το προϊόν φροντίδας
δέρματος/σώματος, τη μέθοδο και τη συσκευή που θα χρησιμοποιηθεί κάνοντας προκαταρκτικές
προετοιμασίες, εφαρμόζει καλλυντικά προϊόντα καθαρισμού, διαδικασίες απολέπισης και χρήση
ατμού , καθαρίζει τους πόρους στο δέρμα, εφαρμόζει τονωτικό, αμπούλα/ορό, ενυδατική κρέμα,
ελέγχει/μετράει πριν την περιποίηση σώματος, κάνει μασάζ περιποίησης και περιποίησης σώματος
με τη συσκευή, καθορίζει την περιοχή και την τεχνική που θα αποτριχωθεί, πραγματοποιεί
αποτρίχωση με βελόνα, φωτοαποτρίχωση και αποτρίχωση με κερί .Είναι εξειδικευμένο άτομο που
διαμορφώνει τα φρύδια, εκτελεί εφαρμογές μακιγιάζ προσδιορίζοντας τον τύπο του μακιγιάζ και
των καλλυντικών που θα χρησιμοποιηθούν και συμμετέχει σε δραστηριότητες επαγγελματικής
ανάπτυξης.</span>
      </div>
    </div>
  )
}

function Certification(){
  let [isOpen, setIsOpen] = useState([false,false,false,false,false,false,false,false,false,false,false,false])
  let [expandText, setExpandText] = useState(["+","+","+","+","+","+","+","+","+","+","+","+"])
  async function handleExpand(number){
    if(isOpen[number-1]){
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `none`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "+"
      setExpandText([...expandText])
    }else{
      document.getElementById(`ExpandContainer${number}`).style.setProperty(`display`, `grid`)
      isOpen[number-1] = !isOpen[number-1]
      expandText[number-1] = "-"
      setExpandText([...expandText])
    }
  }
  return(
    <div id="Certification">
      <img id='TopImage' src={certSlider} alt="slider2" />
      <h1 style={{textAlign:"center", padding:"30px"}}>ΔΙΑΔΙΚΑΣΙΑ ΕΞΕΤΑΣΕΩΝ ΚΑΙ ΠΙΣΤΟΠΟΙΗΣΗΣ MYK/VQA</h1>
      <div id='ButtonSectionContainer'>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(1)}>ΠΑΡΑΛΑΒΗ ΑΙΤΗΣΗΣ ΣΥΜΜΕΤΟΧΗΣ ΥΠΟΨΗΦΙΟΥ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[0]}</h3></h3>
      <span id='ExpandContainer1'>Αφού αποφασίσετε σε ποιες επαγγελματικές εξετάσεις επάρκειας θέλετε να λάβετε μέρος, μπορείτε
να συμπληρώσετε την φόρμα ενδιαφέροντος πριν την αίτηση σας στον ιστότοπό μας. Η ομάδα μας
θα επικοινωνήσει μαζί σας σχετικά με τις λεπτομέρειες το συντομότερο δυνατό. Εναλλακτικά,
μπορείτε να δηλώσετε την εγγραφή σας επικοινωνώντας απευθείας μαζί μας στο +30 694 857 9139
Γενική Διευθύντρια Kυρίκου Εργίνα/+30 6947519369 Τεχνικός/Γραμματεία Γκίβαλου Χριστίνα .</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(2)}>ΕΞΕΤΑΣΗ ΚΑΙ ΕΓΚΡΙΣΗ ΤΗΣ ΑΙΤΗΣΗΣ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[1]}</h3></h3>
      <span id='ExpandContainer2'>Για να εγγραφείτε στις Εξετάσεις MYK Επαγγελματικών Προσόντων/Επάρκειας, δεν υπάρχουν
απαιτήσεις αποφοίτησης ή απαίτηση προ απαιτούμενης επαγγελματικής εμπειρίας (ενσήμων)
.Απαιτείται οι υποψήφιοι να έχουν κλείσει το 18ο έτος της ηλικίας τους ώστε να υποβάλουν αίτηση
για προετοιμασία και συμμετοχή στις εξετάσεις μας. Εγγραφές υποψηφίων που δεν έχουν
προκαταρκτική αίτηση δεν γίνονται δεκτές.
Το Πιστοποιητικό Επαγγελματικής Ικανότητας είναι σημαντικό γιατί είναι επίσημο έγγραφο που
δηλώνει ότι το άτομο που θέλει να ασκήσει ένα επάγγελμα έχει επαρκείς γνώσεις, δεξιότητες και
ικανότητες για την άσκηση του επαγγέλματος του ή την ίδρυση επιχείρησης .
Μετά την ολοκλήρωση της απαραίτητης διαδικασίας προετοιμασίας που σχετίζεται με το
επάγγελμά τους, όσοι συμμετέχουν στο πρόγραμμα Εκπαίδευσης Επαγγελματικών Προσόντων θα
αξιολογηθούν από το ίδρυμά μας δίνοντας εξετάσεις θεωρίας και πρακτικής απόδοσης την επόμενη
ημερομηνία που καθορίζεται περιοδικά από το Ίδρυμα Επαγγελματικών Προσόντων MYK/VQA .Οι
επιτυχόντες των Θεωρητικών και Πρακτικών Επιδόσεων, θα δικαιούνται να λάβουν ένα διεθνώς
έγκυρο έγγραφο εγκεκριμένο από την Αρχή Επαγγελματικών Προσόντων MYK (κρατικό ίδρυμα)
αντιστοιχισμένο στο Ευρωπαϊκό Πλαίσιο Προσόντων (EQF) .
Η τελική εγγραφή σας δημιουργείται αφού έχει ολοκληρωθεί το συνολικό κόστος της εξέτασης μαζί
με τη κατάθεση των επίσημων προσωπικών εγγράφων ταυτοποίησης με φωτογραφία σας
(Ταυτότητα ή Δίπλωμα οδήγησης ή Διαβατήριο).
Μπορείτε να κάνετε την πληρωμή με πιστωτική κάρτα, τραπεζικό λογαριασμό ή μετρητά μόνο στον
τραπεζικό λογαριασμό της Europlus Group . Αιτήσεις χωρίς την απόδειξη πληρωμής δεν θα γίνονται
δεκτές.
ΣΗΜΑΝΤΙΚΗ ΣΗΜΕΙΩΣΗ: Η ΧΡΗΜΑΤΙΚΗ ΚΑΤΑΒΟΛΗ ΤΩΝ ΥΠΟΨΗΦΙΩΝ ΣΤΟΝ ΤΡΑΠΕΖΙΚΟ
ΛΟΓΑΡΙΑΣΜΟ ΤΗΣ EUROPLUS GROUP Η ΟΠΟΙΑ ΕΧΕΙ ΟΛΟΚΛΗΡΩΘΕΙ ΔΕΝ ΑΚΥΡΩΝΕΤΑΙ ΟΥΤΕ
ΕΠΙΣΤΡΕΦΕΤΕ ΓΙΑ ΚΑΝΕΝΑΝ ΛΟΓΟ! ΠΑΡΑΚΑΛΩ ΔΙΑΒΑΣΤΕ ΠΡΟΣΕΚΤΙΚΑ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΠΡΙΝ
ΚΑΝΕΤΕ ΤΗΝ ΑΙΤΗΣΗ ΣΑΣ .</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(3)}>ΔΙΑΔΙΚΑΣΙΑ ΕΞΕΤΑΣΕΩΝ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[2]}</h3></h3>
      <span id='ExpandContainer3' style={{width:"80%", justifySelf:"end", gap:"10px"}}>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(4)}>ΟΔΗΓΙΕΣ ΥΠΟΨΗΦΙΩΝ ΠΡΙΝ ΤΗ ΣΥΜΜΕΤΟΧΗ ΣΤΙΣ ΕΞΕΤΑΣΕΙΣ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[3]}</h3></h3>
        <span id='ExpandContainer4'>Πληροφορίες χορηγούνται σε υποψηφίους των οποίων οι αιτήσεις εξετάσεων έχουν συμπληρωθεί
και των οποίων οι αιτήσεις έχουν εγκριθεί. Οι πληροφορίες δίνονται προφορικά ή μέσω e-mail ή
SMS.
Οι υποψήφιοι ειδοποιούνται για την ημερομηνία και ώρα της θεωρητικής και πρακτικής εξέτασης 1
εβδομάδα πριν από τη συμμετοχή τους στις εξετάσεις.
Όταν ανακοινωθούν οι ημερομηνίες των εξετάσεων, οι υποψήφιοι ενημερώνονται 1 εβδομάδα
νωρίτερα προφορικά ή μέσω e-mail ή SMS.
Οι υποψήφιοι πρέπει να έχουν μαζί τους την ημέρα των εξετάσεων τα έγραφα ταυτοποίησης που
κατέθεσαν κατά την εγγραφή τους στην εξέταση.
Οι υποψήφιοι δεν μπορούν να διεκδικήσουν κανένα δικαίωμα για εξετάσεις που δεν λάβαν
προετοιμασία .
Όσοι δεν παραβρεθούνε την ημερομηνία και ώρα της προκαθορισμένης εξέτασης χάνουν το
δικαίωμα να δώσουν νέα εξέταση. Δεν μπορούν να γίνουν επιστροφές χρημάτων.</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(5)}>ΟΔΗΓΙΕΣ ΥΠΟΨΗΦΙΩΝ ΚΑΤΑ ΤΗ ΔΙΑΡΚΕΙΑ ΤΩΝ ΕΞΕΤΑΣΕΩΝ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[4]}</h3></h3>
        <span id='ExpandContainer5'>Γενικοί κανόνες: <br />
Οι υποψήφιοι που δεν προσέρχονται στις εξετάσεις με ταυτότητα με φωτογραφία (Ταυτότητα /
Δίπλωμα οδήγησης / Διαβατήριο) δεν μπορούν να συμμετάσχουν στις εξετάσεις.
Προκειμένου να πραγματοποιηθούν έγκαιρα οι έλεγχοι ταυτότητας και η τοποθέτηση στον χώρο
των εξετάσεων, οι υποψήφιοι πρέπει να είναι παρόντες στο χώρο των εξετάσεων τουλάχιστον 15-20
λεπτά πριν την έναρξη της εξέτασης και να έχουν μαζί τους τον εξοπλισμό που είναι απαραίτητος
για την εξέταση τους (Ιατρική ποδιά κτλ) .
Ο υποψήφιος δεν μπορεί να καπνίζει κατά τη διάρκεια της εξέτασης.
Η εγκυρότητα των εξετάσεων εξαρτάται, καταρχάς, από την τήρηση των κανόνων των εξετάσεων. Οι
ταυτότητες των υποψηφίων που ενεργούν αντίθετα με τους κανόνες και δεν συμμορφώνονται με
τις προειδοποιήσεις θα καταγράφονται στα πρακτικά και οι εξετάσεις τους θα θεωρούνται άκυρες.
Απαγορεύεται η χρήση κινητών τηλεφώνων, ακουστικών, υπολογιστών τσέπης, ρολόγια με άλλες
λειτουργίες εκτός της λειτουργίας ρολογιού, κάθε είδους συσκευές με δυνατότητες υπολογιστή,
όπλα κ.λπ. όπως και εξοπλισμός όπως : πρόχειρο χαρτί, σημειωματάριο, βιβλίο, λεξικό, ηλεκτρονική
συσκευή με λειτουργία λεξικού, αριθμομηχανή, κανόνας διαφανειών, πυξίδα, μοιρογνωμόνιο,
χάρακα, μνήμη USB, κάρτα μνήμης και ειδικές συσκευές μνήμης. Οι εξετάσεις των υποψηφίων που
διαπιστώνεται ότι χρησιμοποιούν αυτά τα εργαλεία κατά τη διάρκεια της εξέτασης θα θεωρούνται
άκυρες.
Εάν οι υποψήφιοι μιλήσουν μεταξύ τους ή κάνουν απάτη κατά τη διάρκεια της εξέτασης θα
εξαιρεθούν στην διεξαγωγή της εξέτασης .
Οι υποψήφιοι πρέπει να συμφωνήσουν για τη καταγραφή με κάμερα των εξετάσεων (θεωρητική-
πρακτική επίδοση).</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(6)}>ΚΑΝΟΝΕΣ ΘΕΩΡΗΤΙΚΩΝ ΕΞΕΤΑΣΕΩΝ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[5]}</h3></h3>
        <span id='ExpandContainer6'>Οι υποψήφιοι πρέπει να συμμορφώνονται με τους γενικούς και θεωρητικούς κανόνες εξέτασης
κατά τη διάρκεια της εξέτασης.
Οι υποψήφιοι που δεν προσέρχονται στις εξετάσεις με την ταυτότητα ή το δίπλωμα οδήγησης ή το
διαβατήριο δεν θα γίνονται δεκτοί στις εξετάσεις.
Προκειμένου να πραγματοποιηθούν έγκαιρα οι έλεγχοι ταυτότητας και η τοποθέτηση στον χώρο
των εξετάσεων, οι υποψήφιοι πρέπει να είναι παρόντες στο χώρο των εξετάσεων τουλάχιστον 15-20
λεπτά πριν την έναρξη της εξέτασης και να έχουν μαζί τους τον εξοπλισμό που είναι απαραίτητος
για την εξέταση τους (Ιατρική ποδιά κτλ) .
Μην έχετε μαζί σας συσκευές ηλεκτρονικής επικοινωνίας όπως λεξικό, αριθμομηχανή, κινητό
τηλέφωνο, φορητό ραδιόφωνο κατά τη διάρκεια της εξέτασης. Εάν έχετε, παραδώστε αυτές τις
συσκευές σε εμάς. Εάν διαθέτετε αυτές τις συσκευές και επιχειρήσετε να εξαπατήσετε, η εξέτασή
σας θα θεωρηθεί άκυρη.
Κατά τη διάρκεια της εξέτασης, απαγορεύεται η κατανάλωση φαγητού, ποτών ή η χρήση προϊόντων
καπνού στον χώρο της εξέτασης, εκτός από το πόσιμο νερό, τη ζάχαρη και τα υποχρεωτικά
φάρμακα.
Απαγορεύεται αυστηρά η μεταφορά βλαβερών εργαλείων όπως τρύπημα, κοπή ή πυροβόλα όπλα
στον χώρο των εξετάσεων.
Κατά τη διάρκεια της εξέτασης, οι υποψήφιοι απαγορεύεται να συνομιλούν μεταξύ τους ή να
ζητούν υλικό εξέτασης ή βοήθεια. Οι υποψήφιοι δεν μπορούν να ζητούν από άλλον υποψήφιο
μολύβια, γόμες κ.λπ. Δεν μπορούν να μιλήσουν μεταξύ τους. Οι εξετάσεις θα ακυρωθούν αν συμβεί
κάτι από τα παραπάνω .
Είναι αντίθετο με τους κανόνες των εξετάσεων να συμπεριφέρεστε με τρόπο που διαταράσσει τη
σειρά των εξετάσεων ή ενοχλεί άλλους. Όσοι παραβιάσουν τους κανόνες των εξετάσεων θα
απομακρυνθούν από την αίθουσα των εξετάσεων με αναφορά.
Κατά τη διάρκεια της εξέτασης, οι υποψήφιοι πλην αυτών που ολοκλήρωσαν τις εξετάσεις τους
απαγορεύεται να αποχωρήσουν από τον χώρο των εξετάσεων για οποιονδήποτε άλλο λόγο εκτός
από προβλήματα υγείας. Ο υποψήφιος που αποχωρεί από το χώρο των θεωρητικών εξετάσεων θα
θεωρείται ότι έχει αποτύχει στις εξετάσεις.
Τα στοιχεία ταυτότητας και η κατάσταση των υποψηφίων που διαπιστώθηκε ότι απάτησαν ή
απάτησαν κατά τη διάρκεια της εξέτασης θα καταγραφούν και αυτοί οι υποψήφιοι θα θεωρηθούν
ότι απέτυχαν στην εξέταση.
Κατά τη διάρκεια της εξέτασης απαγορεύεται να κάνετε ερωτήσεις σας στον Εξεταστή π.χ.&quot;Το έκανα
σωστά, το έκανα λάθος, θα είναι έτσι;&quot; και τα λοιπά. Απαγορεύεται να κάνετε ερωτήσεις με αυτόν
τον τρόπο.

Μόνο μία από τις τέσσερις επιλογές για κάθε ερώτηση είναι σωστή. Η σωστή επιλογή θα
κωδικοποιηθεί στη σχετική στήλη του φυλλαδίου σας, λαμβάνοντας υπόψη τον αριθμό της
ερώτησης, χωρίς να εκτείνεται πέρα από τον κύκλο. Όλες οι σημάνσεις γίνονται με μολύβι.
Η βαθμολογία θα γίνει μόνο στις σωστές απαντήσεις σας, χωρίς να ληφθούν υπόψη οι λάθος
απαντήσεις σας. Η λάθος απάντηση δεν επηρεάζει τη σωστή απάντηση. Επομένως, συνιστάται να
μην αφήνετε κενές ερωτήσεις.
Όταν ολοκληρωθεί ο χρόνος της γραπτής εξέτασης, οι απαντήσεις πρέπει να σημειωθούν στη
φόρμα κλειδιού απαντήσεων. Οι απαντήσεις που σημειώνονται στο φυλλάδιο ερωτήσεων δεν είναι
έγκυρες.
Για ερωτήσεις πολλαπλής επιλογής, επιλέξτε μόνο την επιλογή που πιστεύετε ότι είναι σωστή.
Ερωτήσεις με επισημασμένες δύο ή περισσότερες επιλογές θα θεωρούνται λανθασμένες.
Υποψήφιος που εγκαταλείπει την αίθουσα εξετάσεων για οποιονδήποτε λόγο, εκτός από τον
εξεταστή, δεν θα επιτρέπεται να επιστρέψει.
Η διάρκεια, οι ώρες έναρξης και λήξης των εξετάσεων ανακοινώνονται στους υποψηφίους από τους
υπαλλήλους.
Η εξέταση ξεκινά αφού ο αξιολογητής της εξέτασης πει &quot;η εξέταση ξεκίνησε&quot;. Η εξέταση τελειώνει
με τη δήλωση «Η εξέταση τελείωσε».
Οι εξετάσεις καταγράφονται με κάμερα από την Paksoy Certification. Δεν θα επιτρέπεται να φύγετε
από τον χώρο των εξετάσεων κατά τη διάρκεια της εξέτασης.
*Σε περίπτωση έκτακτης ανάγκης κατά τη διάρκεια της εξέτασης, ο κόσμος θα συγκεντρωθεί στον
χώρο έκτακτης συνάντησης.</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(7)}>ΚΑΝΟΝΕΣ ΕΞΕΤΑΣΕΩΝ ΠΡΑΚΤΙΚΗΣ ΕΠΙΔΟΣΗΣ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[6]}</h3></h3>
        <span id='ExpandContainer7'>Οι υποψήφιοι που δεν προσέρχονται στις εξετάσεις με την ταυτότητα ή το δίπλωμα οδήγησης ή το
διαβατήριο δεν θα γίνονται δεκτοί στις εξετάσεις.
Προκειμένου να πραγματοποιηθούν έγκαιρα οι έλεγχοι ταυτότητας και η τοποθέτηση στον χώρο
των εξετάσεων, οι υποψήφιοι πρέπει να είναι παρόντες στο χώρο των εξετάσεων τουλάχιστον 15-20
λεπτά πριν την έναρξη της εξέτασης και να έχουν μαζί τους τον εξοπλισμό που είναι απαραίτητος
για την εξέταση τους (Ιατρική ποδιά κτλ) .
Όταν κριθεί απαραίτητο, ο Εξεταστής μπορεί να αλλάξει καθήκοντα μεταξύ των εργαζομένων σε
καταστάσεις που απαιτούν ομαδική εργασία κατά τη διάρκεια της εξέτασης.
Οι εξετάσεις υποψηφίων που παραβιάζουν τους κανόνες υγιεινής και ασφάλειας στην εργασία και
ως εκ τούτου θέτουν σε κίνδυνο τους εαυτούς τους και άλλα άτομα στο χώρο των εξετάσεων θα
διακόπτεται. Ο εν λόγω υποψήφιος θα θεωρηθεί ότι απέτυχε στις εξετάσεις.
Μην έχετε μαζί σας συσκευές ηλεκτρονικής επικοινωνίας όπως λεξικά, αριθμομηχανές , κινητά
τηλέφωνα, ραδιοτηλέφωνα ή ραδιόφωνα κατά τη διάρκεια της εξέτασης. Εάν έχετε, παραδώστε
αυτές τις συσκευές στο προσωπικό της αίθουσας. Εάν διαθέτετε αυτές τις συσκευές και
επιχειρήσετε να εξαπατήσετε, η εξέτασή σας θα θεωρηθεί άκυρη.
Κατά τη διάρκεια της εξέτασης, απαγορεύεται η κατανάλωση φαγητού, ποτών ή η χρήση προϊόντων
καπνού στον χώρο της εξέτασης, εκτός από το πόσιμο νερό, τη ζάχαρη και τα υποχρεωτικά
φάρμακα.
Απαγορεύεται η μεταφορά επικίνδυνων εργαλείων όπως μυτερά εργαλεία, μαχαίρια ή πυροβόλα
όπλα στον χώρο των εξετάσεων.
Είναι αντίθετο με τους κανόνες των εξετάσεων να συμπεριφέρεστε με τρόπο που διαταράσσει τη
σειρά των εξετάσεων ή ενοχλεί άλλους. Όσοι ενεργούν ενάντια στους κανόνες των εξετάσεων θα
απομακρυνθούν από τον χώρο των εξετάσεων με αναφορά.
Οι υποψήφιοι που ολοκληρώνουν τις εξετάσεις τους κατά τη διάρκεια της εξέτασης (ολόκληρο το
γκρουπ σε ομαδικές εξετάσεις) απαγορεύεται να εγκαταλείψουν το χώρο εξέτασης για

οποιονδήποτε άλλο λόγο εκτός από προβλήματα υγείας, υποχρεωτικές ανάγκες και άδεια
(διάλειμμα) που δίνεται από τον Εξεταστή. Ο υποψήφιος που αποχωρεί από το χώρο εξέτασης
θεωρείται ότι έχει αποτύχει στις εξετάσεις.
Απαγορεύεται στους υποψηφίους να συνομιλούν μεταξύ τους ή να ζητούν υλικό ή βοήθεια κατά τη
διάρκεια της εξέτασης (εκτός της ομάδας).
Κατά τη διάρκεια της εξέτασης, καλείστε να κάνετε τις ερωτήσεις σας στον Εξεταστή δυνατά και με
τρόπο που να μπορούν να ακούσουν όλοι . Απαγορεύονται ερωτήσεις όπως :Έκανα το σωστό με την
ερώτηση της εξέτασης, το έκανα λάθος, θα συμβεί έτσι; κ.λ.π..
Οι εξετάσεις καταγράφονται με κάμερα από την πιστοποίηση Paksoy. Δεν θα επιτρέπεται να φύγετε
από τον χώρο των εξετάσεων κατά τη διάρκεια της εξέτασης.</span>
        <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(8)}>ΚΑΝΟΝΙΣΜΟΙ ΥΓΙΕΙΝΗΣ ΚΑΙ ΑΣΦΑΛΕΙΑΣ ΣΤΗΝ ΕΡΓΑΣΙΑ ΚΑΙ ΔΙΑΔΙΚΑΣΙΕΣ ΕΚΤΑΚΤΗΣ ΑΝΑΓΚΗΣ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[7]}</h3></h3>
        <span id='ExpandContainer8'>Συμμορφωθείτε με τους Κανόνες Ασφάλειας στην Εργασία και χρησιμοποιήστε Μέσα Ατομικής
Προστασίας σύμφωνα με τις απαιτήσεις της εργασίας.
Κάθε υποψήφιος είναι υπεύθυνος για την ασφάλειά του. Πρέπει να χρησιμοποιεί κάθε είδους
εξοπλισμό και εργαλεία που σχετίζονται με την ασφάλεια στην εργασία. Σε αντίθετη περίπτωση, όλη
η ευθύνη ανήκει στους υποψηφίους.
Κατά τη διάρκεια της εξέτασης, να τηρείτε τους κανόνες και τις πινακίδες ασφάλειας εργασίας στον
χώρο εξέτασης.
Κατά τη διάρκεια της εξέτασης, εάν παρουσιαστεί οποιαδήποτε κατάσταση που μπορεί να θέσει σε
κίνδυνο την Ασφάλεια και την Υγεία στην Εργασία, ακολουθήστε τις οδηγίες ασφαλείας που
δίνονται από τον εξεταστή.
Κατά τη διάρκεια της εξέτασης, οι υποψήφιοι πρέπει να αποφεύγουν οποιαδήποτε συμπεριφορά
που μπορεί να θέσει σε κίνδυνο την Ασφάλεια και την Υγεία της Εργασίας.
*Σε περίπτωση έκτακτης ανάγκης κατά τη διάρκεια της εξέτασης, κόσμος θα συγκεντρωθεί στον
χώρο έκτακτης συνάντησης.</span>
      </span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(9)}>ΔΙΑΔΙΚΑΣΙΑ ΑΞΙΟΛΟΓΗΣΗΣ ΚΑΙ ΑΝΑΚΟΙΝΩΣΗ ΑΠΟΤΕΛΕΣΜΑΤΩΝ ΤΩΝ ΕΞΕΤΑΣΕΩΝ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[8]}</h3></h3>
      <span id='ExpandContainer9'>Για να λάβει το Πιστοποιητικό Επαγγελματικής Ικανότητας, ο υποψήφιος πρέπει να είναι επιτυχής
τόσο σε θεωρητικές εξετάσεις όσο και σε εξετάσεις πρακτικής επίδοσης σε όλες τις μονάδες που
επιλέγονται για το σχετικό επαγγελματικό προσόν.
Οι υποψήφιοι ενημερώνονται από την εταιρία μας για αποτελέσματα των εξετάσεων προφορικά,
μέσω e-mail ή SMS.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(10)}>ΔΙΑΔΙΚΑΣΙΑ ΠΙΣΤΟΠΟΙΗΣΗΣ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[9]}</h3></h3>
      <span id='ExpandContainer10'>Για να λάβει Πιστοποιητικό Επαγγελματικής Ικανότητας, ο υποψήφιος να είναι επιτυχής τόσο σε
θεωρητικές εξετάσεις όσο και σε εξετάσεις πρακτικής επίδοσης σε όλες τις μονάδες που επιλέγονται
για το σχετικό επαγγελματικό προσόν.
Οι υποψήφιοι ενημερώνονται από την εταιρία μας για αποτελέσματα των εξετάσεων προφορικά,
μέσω e-mail ή SMS.
Εάν ο υποψήφιος δικαιούται να λάβει ένα έγγραφο, το Πιστοποιητικό Επαγγελματικής Ικανότητας
του υποψηφίου και τα παραρτήματά του συντάσσονται από την MYK (Αρχή Επαγγελματικών
Προσόντων) και παραδίδονται στον υποψήφιο εντός 45 ημερών από τον Διαπιστευμένο Φορέα
Πιστοποιήσεων Paksoy και το ίδρυμά μας για να εγκριθούν και να γίνει εγγραφή των
πιστοποιημένων στο μητρώο .

Αν το έγγραφο είναι ελαττωματικό, σκισμένο ή χαθεί κ.λ.π. Σε περίπτωση αιτήματος για
επανεκτύπωση εγγράφων όπως, ο πιστοποιημένος πρέπει να ζητήσει επανεκτύπωση εγγράφου από
το εξεταστικό κέντρο της Ελλάδος για αίτηση νέας εκτύπωσης. Μετά την καταβολή του τέλους
εκτύπωσης που καθορίζεται για το σχετικό προσόν, το Πιστοποιητικό Επαγγελματικής Ικανότητας
του υποψηφίου και τα παραρτήματά του συντάσσονται από την MYK (Αρχή Επαγγελματικών
Προσόντων) και παραδίδονται στον υποψήφιο από την Πιστοποίηση Paksoy μετά την έγκριση.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(11)}>ΑΚΥΡΩΣΗ ΕΞΕΤΑΣΕΩΝ ΚΑΙ ΕΠΑΝΑΛΗΨΗ ΕΞΕΤΑΣΕΩΝ <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[10]}</h3></h3>
      <span id='ExpandContainer11'>Σε περιπτώσεις γεγονότων που μπορεί να συμβούν κατά τη διάρκεια της εξέτασης και απαιτούν
επανάληψη της εξέτασης (πυρκαγιά, πλημμύρα, σεισμός, επιδρομή, επίθεση, έκρηξη, μαζικό
περιστατικό, διαμαρτυρία κ.λπ.) και σε περιπτώσεις αμφιβολίας σχετικά με τη διαύγεια των
εξετάσεων, η διεξαγωγή της εξέτασης καταγράφεται στα πρακτικά. Η εξέταση μπορεί να ακυρωθεί
μετά την απαραίτητη αξιολόγηση. Η ακυρωθείσα εξέταση θα διεξαχθεί εκ νέου εντός 15 εργάσιμων
ημερών, εκτός ανωτέρας βίας, με διόρθωση των περιστάσεων που προκάλεσαν την ακύρωση. Δεν
θα ζητηθεί κανένα επιπλέον κόστος εξέτασης από τον υποψήφιο που θα δώσει ξανά την εξέταση. Οι
υποψήφιοι που θα έχει εμπλέκονται σε μαζική εξαπάτηση δεν θα επιτραπεί να συμμετάσχουν ξανά
στις εξετάσεις.</span>
      <h3 style={{display:"grid", gridAutoFlow:"column",gap:"10px"}} onClick={e => handleExpand(12)}>ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ ΓΙΑ ΤΟ MYK/VQA <h3 style={{width:"fit-content", justifySelf:"end"}}>{expandText[11]}</h3></h3>
      <span id='ExpandContainer12' style={{gap:"20px"}}>
        <h2>1. ΤΙ ΕΙΝΑΙ ΤΟ ΠΙΣΤΟΠΟΙΗΤΙΚΟ επαγγελματικών προσόντων της MYK/VQA</h2>
        <p>Το Πιστοποιητικό Επαγγελματικών Προσόντων είναι ένα έγγραφο που μπορούν να έχουν τα άτομα
σε οποιοδήποτε επάγγελμα εάν πληρούν τις προϋποθέσεις που καθορίζονται στα επαγγελματικά
προσόντα που δημιουργούνται από την Αρχή Επαγγελματικών Προσόντων (ΜΥΚ).
Ιατρική, οδοντιατρική, νοσηλευτική, μαιευτική, φαρμακευτική, κτηνιατρική, αρχιτεκτονική
(επαγγέλματα που εμπίπτουν στο πεδίο της αυτόματης αναγνώρισης στην Ευρωπαϊκή Ένωση),
επαγγέλματα μηχανικού και επαγγέλματα που απαιτούν τουλάχιστον προπτυχιακή εκπαίδευση και
των οποίων οι προϋποθέσεις εισόδου στο επάγγελμα ρυθμίζονται από το νόμο. Eξαιρούνται από
αυτό το πεδίο εφαρμογής· Η πιστοποίηση επαγγελματικής επάρκειας για επικύρωση προσόντων
VQA ισχύει για όλα τα επαγγέλματα εκτός από αυτά.</p>
<h2>2. ΠΟΙΟΣ ΕΙΝΑΙ Ο ΣΚΟΠΟΣ ΤΟΥ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK</h2>
<p>Το πιστοποιητικό επαγγελματικών προσόντων επιτρέπει σε ένα άτομο να τεκμηριώνει επίσημα τις γνώσεις, τις δεξιότητες και τις ικανότητές του στο επάγγελμά του σε οποιαδήποτε επαγγελματική κατηγορία.
   Όταν έχετε πιστοποίηση επαγγελματικής επάρκειας , θεωρείστε ειδικός σε αυτό το επάγγελμα, ανάλογα με την κατηγορία επαγγέλματος για την οποία αποκτήσατε τη πιστοποίηση , 
   και μπορείτε να αποδείξετε ότι είστε εξειδικευμένος και έχετε την ικανότητα άσκησης του επαγγέλματος σας .</p>
<h2>3. ΠΩΣ ΝΑ ΑΠΟΚΤΗΣΩ ΠΙΣΤΟΠΟΙΗΣΗ επαγγελματικής επάρκειας</h2>
<p>Για να αποκτήσετε την πιστοποίηση , πρέπει να είστε έμπειροι και γνώστες αυτού του επαγγέλματος ώστε να αποδείξετε την ικανότητα σας μετά τη διεξαγωγή της θεωρητικής και πρακτικής εξέτασης.
Η διαδικασία της Επαγγελματικής Επάρκειας VQA είναι εξ ολοκλήρου μια διαδικασία μέτρησης και αξιολόγησης. 
Ο υποψήφιος υποβάλλεται σε 2 εξετάσεις που σχετίζονται με την εργασία του. Αν πάρει έγκυρο βαθμό από αυτές τις εξετάσεις, εκ των οποίων η μία είναι ΘΕΩΡΗΤΙΚΗ και η άλλη εξέταση πρακτικής απόδοσης, 
θα μπορεί να λάβει δίκαια τη πιστοποίηση ΕΠΑΓΓΕΛΜΑΤΙΚΗΣ ΕΠΑΡΚΕΙΑΣ/ΙΚΑΝΟΤΗΤΑΣ VQA ΤΩΝ ΔΙΕΘΝΩΝ ΠΡΟΣΟΝΤΩΝ ΤΟΥ.</p>
<h2>4. ΜΠΟΡΩ ΝΑ ΛΑΒΩ ΠΛΗΡΟΦΟΡΙΕΣ ΣΧΕΤΙΚΑ ΜΕ ΤΙΣ ΕΞΕΤΑΣΕΙΣ ΚΑΙ ΤΗΝ ΠΙΣΤΟΠΟΙΗΣΗ ΤΟΥ ΙΔΡΥΜΑΤΟΣ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK/VQA</h2>
<p>Ένα από τα πιο σημαντικά στοιχεία του εθνικού συστήματος προσόντων είναι οι δραστηριότητες εξέτασης και πιστοποίησης. 
  Το Πιστοποιητικό Επαγγελματικών Προσόντων MYK, το οποίο είναι το σημαντικότερο αποτέλεσμα αυτών των δραστηριοτήτων, 
  εκδίδεται σε άτομα που αξίζουν ως αποτέλεσμα δραστηριοτήτων μέτρησης και αξιολόγησης που πραγματοποιούνται με θεωρητικό και πρακτικό τρόπο, με βάση τα εθνικά προσόντα, υπό συνθήκες διασφάλισης ποιότητας.
Οι υπηρεσίες εξέτασης και πιστοποίησης για άτομα που ζητούν Πιστοποιητικό Επαγγελματικών Προσόντων παρέχονται μέσω φορέων πιστοποίησης εξουσιοδοτημένοι από την Αρχή Επαγγελματικών Προσόντων της MYK.
Οι φορείς πιστοποίησης προσωπικού που είναι διαπιστευμένοι από τον Τουρκικό Οργανισμό Διαπίστευσης (TÜRKAK) 
σύμφωνα με το πρότυπο πιστοποίησης προσωπικού "ISO/IEC 17024 Conformity Assessment - General Conditions for Organisations Conducting Personnel Certification" 
και εξουσιοδοτημένοι από το κρατικό Ίδρυμα MYK πληρούν τις προϋποθέσεις διασφάλισης ποιότητας που καθορίζονται από τη νομοθεσία και τη καθοδήγηση του ιδρύματος,
 διενεργούν εξέταση και πιστοποίηση έναντι του ιδρύματος.</p>
 <h2>5. ΓΙΑΤΙ ΕΙΝΑΙ ΣΗΜΑΝΤΙΚΟ ΤΟ ΠΙΣΤΟΠΟΙΗΤΙΚΟ ΤΟΥ ΙΔΡΥΜΑΤΟΣ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ
MYK</h2>
 <p>Στη σημερινή επιχειρηματική ζωή, τα άτομα πρέπει να τεκμηριώνουν τις γνώσεις, τις δεξιότητες και
τις εμπειρίες τους προκειμένου να δημιουργήσουν μια καριέρα για τον εαυτό τους.
Οι πιστοποιήσεις είναι πρότυπα πιστοποίησης που μετρούν και αξιολογούν την εμπειρία/γνώση
που απαιτείται για ένα καθορισμένο επίπεδο γνώσεων και δεξιοτήτων. Αυτό είναι απαραίτητο για
τη διάκριση του ειδικευμένου εργατικού δυναμικού από τους ανθρώπους που λένε «μπορώ να τα
καταφέρω», παρόλο που δεν έχουν επαρκή προσόντα και προσπαθούν να εξαλείψουν επαρκή
προσόντα με χαμηλούς μισθούς .</p>
 <h2>6. ΥΠΑΡΧΕΙ ΠΡΟΫΠΟΘΕΣΗ ΓΙΑ ΕΙΣΑΓΩΓΗ ΣΤΙΣ ΕΞΕΤΑΣΕΙΣ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK</h2>
 <p>Δεν υπάρχουν προαπαιτούμενα ή απαιτήσεις αποφοίτησης ή απασχόλησης για τη συμμετοχή στις
Εξετάσεις Επαγγελματικών Προσόντων MYK/VQA.</p>
 <h2>7. ΣΕ ΠΟΙΕΣ ΓΛΩΣΣΕΣ ΕΙΝΑΙ Η ΠΙΣΤΟΠΟΙΗΣΗ ΕΠΑΡΚΕΙΑΣ επαγγελματικών προσόντων της MYK/VQA</h2>
 <p>Η Πιστοποίηση Επαγγελματικής Επάρκειας Ικανότητας MYK, χάρη στη διεθνή αναγνώρισή της,
ζητείται ειδικά από πολίτες που εργάζονται σε ξένα έργα και επιχειρήσεις και ενώ οι εργαζόμενοι
πρέπει να μεταφράσουν τα έγγραφά τους με ένορκη μεταφραστική υπηρεσία, ειδικά στα αγγλικά,
τώρα το Πιστοποιητικό Επαγγελματικής Ικανότητας MYK μπορεί να εκδίδεται δίγλωσσο, χωρίς
πρόσθετη χρέωση, ανάλογα με τη γλώσσα της χώρας που θα εργαστείτε .Με τη ρύθμιση αυτή
διασφαλίζεται ότι δαπάνες όπως η ένορκη μετάφραση και η έγκριση συμβολαιογράφου δεν θα
επιβαρύνουν επιπλέον το πιστοποιημένο εργατικό δυναμικό.
Τα Πιστοποιητικά Επαγγελματικών Προσόντων MYK εκδίδονται σε δίγλωσσα Τουρκικά-Αγγλικά για
όλα τα επαγγέλματα από το 2019. Εκτός από Τουρκικά-Αγγλικά, μπορούν να εκδοθούν και σε
γλώσσες όπως Τουρκικά-Γερμανικά, Τουρκικά-Γαλλικά, Τουρκικά-Ισπανικά, Τουρκικά- Ρωσικά και
Τουρκικά-Αραβικά, ανάλογα με την προτίμηση των υποψηφίων. Υπάρχει επίσης η δυνατότητα
επεξεργασίας του .</p>
 <h2>8. ΔΕΝ ΞΕΡΩ ΤΟΥΡΚΙΚΑ, ΜΠΟΡΩ ΝΑ ΔΩΣΩ ΕΠΑΓΓΕΛΜΑΤΙΚΕΣ ΕΞΕΤΑΣΕΙΣ</h2>
 <p>Για υποψήφιους που δεν μιλούν τουρκικά συμμετέχει ορκωτός μεταφραστής που θα διαβάσει τις
ερωτήσεις για τους υποψηφίους που δεν μιλούν τουρκικά, τον οποίο ορίζει υπεύθυνο ο φορέας
Paksoy και τον εξουσιοδοτεί για τις εξετάσεις γραπτές και πρακτικές . Οι εξετάσεις των υποψηφίων
που έχουν πρόβλημα ακοής διενεργούνται με παρουσία μεταφραστή χωριστά από τους άλλους
υποψηφίους.</p>
 <h2>9. ΕΙΧΑ ΕΠΙΤΥΧΙΑ ΣΤΙΣ ΕΞΕΤΑΣΕΙΣ. ΠΟΤΕ ΜΠΟΡΩ ΝΑ ΠΑΡΩ ΤΟ ΕΓΓΡΑΦΟ ΜΟΥ</h2>
 <p>Τα έγγραφα και οι ταυτότητες παραδίδονται στο ίδρυμά μας εντός 45 εργάσιμων ημερών .</p>
 <h2>10. ΑΝ ΚΑΙ ΤΟ ΑΠΟΤΕΛΕΣΜΑ ΕΞΕΤΑΣΕΩΝ ΜΟΥ ΕΙΝΑΙ 90 ΜΟΝΑΔΕΣ, ΑΠΕΤΥΧΑ ΣΤΙΣ ΕΞΕΤΑΣΕΙΣ. ΠΩΣ
ΜΠΟΡΕΙ ΝΑ ΣΥΜΒΕΙ ΑΥΤΟ</h2>
 <p>Τα κρίσιμα βήματα περιλαμβάνονται στην πλειονότητα των επαγγελματικών προσόντων. Το κρίσιμο
βήμα αναφέρεται στις εργασίες και τις διαδικασίες που πρέπει να ολοκληρώσει ο υποψήφιος για να
ολοκληρώσει με επιτυχία την εξέταση. Οι υποψήφιοι που δεν επιτύχουν στα κρίσιμα βήματα
θεωρούνται αποτυχόντες στην εν λόγω εξέταση, ανεξάρτητα από το βαθμό που έλαβαν ως
αποτέλεσμα της εξέτασης.</p>
 <h2>11. ΤΙ ΔΙΑΡΚΕΙΑ ΙΣΧΥΣ ΕΧΕΙ ΤΟ ΠΙΣΤΟΠΟΙΗΤΙΚΟ MYK; ΤΙ ΣΥΜΒΑΙΝΕΙ ΟΤΑΝ ΛΗΞΕΙ Η ΙΣΧΥΣ ΤΟΥ
ΕΓΓΡΑΦΟΥ</h2>
 <p>Άτομο με Πιστοποιητικό Επαγγελματικής Κατάρτισης MYK μπορεί να ανανεώσει το πιστοποιητικό
του πριν λήξει η περίοδος ισχύος. Αν δεν ολοκληρώσει τη διαδικασία ανανέωσης, το πιστοποιητικό
του θα λήξει όταν ολοκληρωθεί η περίοδος ισχύος.
Τα Πιστοποιητικά Επαγγελματικών Προσόντων είναι έγγραφα που έχουν περιόδους ισχύος και
πρέπει να ανανεώνονται όταν λήξουν αυτές οι περίοδοι. Η περίοδος και οι προϋποθέσεις
ανανέωσης του Πιστοποιητικού Επαγγελματικού Προσόντων καθορίζονται στα Εθνικά Προσόντα. Οι
περίοδοι ανανέωσης του Πιστοποιητικού Επαγγελματικού Προσόντων είναι ως επί το πλείστον 5
(πέντε) έτη. 6 μήνες πριν από την ημερομηνία λήξης του εγγράφου, το πιστοποιητικό
επαγγελματικών προσόντων όσων αποδεικνύουν ότι έχουν εργαστεί στο σχετικό επάγγελμα για 2
χρόνια ή έχουν εργαστεί στο σχετικό επάγγελμα τους τελευταίους 6 μήνες με προϋπηρεσία του
ωραρίου εργασίας τους, να ανανεωθεί χωρίς να δώσει εξετάσεις.
Σε περίπτωση που λήξει το πιστοποιητικό σας και δεν έχει ανανεωθεί τότε πρέπει να συμμετέχετε
σε νέα εξέταση ώστε να λάβετε νέα αδειοδότηση.</p>
 <h2>12. ΕΧΑΣΑ ΤΟ ΠΙΣΤΟΠΟΙΗΤΙΚΟ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK. ΜΠΟΡΕΙΤΕ ΝΑ ΤΟ ΕΚΤΥΠΩΣΕΤΕ
ΚΑΙ ΝΑ ΜΟΥ ΣΤΕΙΛΕΤΕ</h2>
 <p>Εάν το αίτημά σας προωθηθεί στο ίδρυμά μας, θα ζητήσουμε άλλες απαραίτητες πληροφορίες και
έγγραφα από τον αιτούντα υποψήφιο, έναντι χρέωσης εγγράφων. Διασφαλίζουμε ότι το σχετικό
έγγραφο ή/και το δελτίο ταυτότητας θα επανεκτυπωθεί και θα παραδοθεί σε εσάς.</p>
 <h2>13. ΠΩΣ ΕΞΑΣΦΑΛΙΖΕΤΑΙ Η ΣΥΓΚΡΙΣΙΜΟΤΗΤΑ ΤΩΝ ΠΙΣΤΟΠΟΙΗΤΙΚΩΝ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ
ΣΕ ΔΙΕΘΝΕΣ ΕΠΙΠΕΔΟ</h2>
 <p>Μία από τις πιο σημαντικές αποστολές της MYK είναι να διασφαλίσει ότι το τουρκικό εργατικό
δυναμικό έχει την ποιότητα και την κινητικότητα για να ανταγωνιστεί σε παγκόσμιο επίπεδο. Για το
λόγο αυτό, είναι πολύ σημαντικό τα εν λόγω έγγραφα να παρέχουν διασφάλιση προσόντων μέσω
του συστήματος ισοδυναμίας και να έχουν δομή συμβατή με τα πλαίσια προσόντων σε διεθνές
επίπεδο. Στο πλαίσιο αυτό, η MYK διασφαλίζει την ανάπτυξη του Εθνικού Πλαισίου Προσόντων
συμβατό με το Ευρωπαϊκό Πλαίσιο Προσόντων. Η διασφάλιση ποιότητας των διαδικασιών
τεκμηρίωσης παρέχεται μέσω της Türkak ή οργανισμών ισοδυναμίας που είναι μέρη της
πολυμερούς συμφωνίας περιγραφής εντός της Ευρωπαϊκής Ένωσης Ισοδυναμίας, επιπλέον των
μέτρων διασφάλισης ποιότητας που εφαρμόζει η MYK/VQA.</p>
 <h2>14. ΙΣΧΥΕΙ ΣΤΟ ΕΞΩΤΕΡΙΚΟ ΤΟ ΠΙΣΤΟΠΟΙΗΤΙΚΟ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK/VQA</h2>
 <p>1. Συμπλήρωμα πιστοποιητικού Europass: Σκοπός της Αρχής Επαγγελματικών Προσόντων, η οποία ιδρύθηκε στις 21 Σεπτεμβρίου 2006, είναι η διαχείριση των διαδικασιών πιστοποίησης μέσω μέτρησης και αξιολόγησης, προκειμένου να προσδιοριστούν οι επαγγελματικές γνώσεις, οι δεξιότητες και τα εθνικά προσόντα των ειδικευμένων επαγγελματιών. Αυτό το έγγραφο, το οποίο έχει καταστεί υποχρεωτικό για ένα ευρύ φάσμα επαγγελμάτων στην Τουρκία, ισχύει και στο εξωτερικό.
Απόφαση 2241/2004/ΕΚ του Ευρωπαϊκού Κοινοβουλίου και του Συμβουλίου, της 15ης Δεκεμβρίου 2004, σχετικά με ένα ενιαίο πλαίσιο διαφάνειας για τους τίτλους σπουδών, με στόχο να διασφαλιστεί ότι οι τίτλοι που αναγνωρίζονται στις χώρες της Ευρωπαϊκής Ένωσης είναι σαφώς και εύκολα κατανοητοί στην Ευρωπαϊκή Ένωση, συμπεριλαμβανομένων των υποψηφίων χωρών , αυξάνοντας έτσι την απασχολησιμότητα και την κινητικότητα αποφοίτων και φοιτητών .Το EUROPASS δημιουργήθηκε για αυτό το σκοπό (Ευρωπαϊκό Διαβατήριο).
Το σύστημα Europass διασφαλίζει ότι τα πιστοποιητικά επαγγελματικών προσόντων MYK είναι έγκυρα όχι μόνο στο εσωτερικό αλλά και διεθνώς. Με αυτόν τον τρόπο, όλα τα έγγραφα επαγγελματικών προσόντων που λαμβάνετε στην Τουρκία ισχύουν στις ευρωπαϊκές χώρες και σε όλες τις χώρες που δέχονται το Europass.
2. Πρότυπο TS EN ISO/IEC 17024: (MYK) Εξετάσεις Επαγγελματικών Προσόντων, TS EN ISO/IEC 17024 σε σχετικά εθνικά προσόντα από τον Τουρκικό Οργανισμό Διαπίστευσης (TÜRKAK) ή άλλους φορείς διαπίστευσης που έχουν υπογράψει πολυμερή συμφωνία αναγνώρισης στο πλαίσιο της European Cooperation for Accreditation(ΕΑ) και την αμοιβαία αναγνώριση πιστοποιητικών (ΕΑ-ΜLA) . Διενεργείται από οργανισμούς που είναι διαπιστευμένοι σύμφωνα με το πρότυπο και εξουσιοδοτημένοι να διεξάγουν δραστηριότητες εξέτασης και πιστοποίησης σύμφωνα με τα εθνικά προσόντα ως αποτέλεσμα της εξέτασης, του ελέγχου και της αξιολόγησης που διενεργείται από την Αρχή Επαγγελματικών Προσόντων (MYK). ).
Οι δραστηριότητες διαπίστευσης της TÜRKAK· Αναγνωρίζεται, παρακολουθείται και επιθεωρείται από το International Accreditation Forum (IAF) και την European Accreditation Association (EA).
(MYK) Το Πιστοποιητικό Επαγγελματικού Προσόντων ισχύει σε χώρες που έχουν υπογράψει συμφωνία αμοιβαίας αναγνώρισης μεταξύ της χώρας μας και άλλων χωρών.
Οι τίτλοι επάρκειας αναγνωρίζονται στην Ελλάδα καθώς πληρούν τις προϋποθέσεις της Αυτόματης Αναγνώρισης ,βάσει κοινών Αρχών εκπαίδευσης , κοινή δοκιμασία επάρκειας , Άρθρο 49α ,Κοινό πλαίσιο εκπαίδευσης (άρθρο 1 παρ. 38 της οδηγίας 2013/55/ΕΕ)Όπως προστέθηκε με το Άρθρο 1 Π.Δ. 51/2017 με ισχύ την 18/1/2016 σύμφωνα με το Άρθρο 2 Π.Δ. 51/2017. Όπως τροποποιήθηκε το Προεδρικό Διάταγμα 38/2010 (Α 78) .
Εξαιρούνται / απαλλάσσονται από πράξη αναγνώρισης και επαγγελματικής ισοδυναμίας / έκδοση νόμιμων προϋποθέσεων-αδειοδότηση / διασυνοριακή δήλωση .</p>
 <h2>15. ΤΙ ΕΙΝΑΙ ΠΡΟΤΥΠΟ TS EN ISO/IEC 17024</h2>
 <p>Το πρότυπο TS EN ISO IEC 17024 είναι το πρότυπο που καθορίζει τις γενικές συνθήκες για τους
οργανισμούς που εκτελούν αξιολόγηση συμμόρφωσης και πιστοποίηση προσωπικού.
Προετοιμάστηκε από την Επιτροπή ISO. Εγκρίθηκε από την CEN το 2012.
Το πρότυπο TS EN ISO IEC 17024 έχει μια χρήσιμη προσέγγιση για τις «δυνατότητες προσωπικού».
Αυτή η προσέγγιση αυξάνει σημαντικά την επιχειρηματική απόδοση για τον οργανισμό.
Καταδεικνύει επίσης τη σημασία και την αξία της πιστοποίησης προσωπικού.
Το πρότυπο περιλαμβάνει πολλές απαιτήσεις για το σύστημα διαχείρισης ποιότητας για την
ανάπτυξη και τη διασφάλιση της συνέχειας των πρακτικών πιστοποίησης του προσωπικού και
επίσης ορίζει τις απαιτήσεις που αναζητούνται σε οργανισμούς που εκτελούν διαδικασίες
πιστοποίησης προσωπικού σύμφωνα με ορισμένες απαιτήσεις, συμπεριλαμβανομένης της
ανάπτυξης και ρύθμισης του σχεδιασμού πιστοποίησης .
Οι εθνικοί φορείς διαπίστευσης που διαπιστεύουν τους οργανισμούς πιστοποίησης προσωπικού
τους δίνουν το δικαίωμα να χρησιμοποιούν το δικό τους λογότυπο, δηλαδή να χρησιμοποιούν τη
δική τους εικόνα και εξουσία. Θεωρείται επίσης απαίτηση για τους φορείς πιστοποίησης
προσωπικού να είναι πλήρως οικονομικά ανεξάρτητοι και οι οικονομικοί πόροι τους εξετάζονται
επίσης από τους εθνικούς φορείς διαπίστευσης.
Σε γενικές γραμμές, το πρότυπο διαπίστευσης με αριθμό ISO 17024,
Είναι ένα σύνολο κανόνων/υποχρεώσεις που τηρούν οι εταιρείες – φορείς που είναι διαπιστευμένες
από την TÜRKAK και εξουσιοδοτημένες από τη MYK στην ικανότητα για τη διεξαγωγή εξετάσεων και
εποπτεύονται από αυτούς .</p>
 <h2>16. ΧΡΗΣΗ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ ΕΠΑΓΓΕΛΜΑΤΙΚΩΝ ΠΡΟΣΟΝΤΩΝ MYK ΣΤΟ ΕΞΩΤΕΡΙΚΟ</h2>
 <p>Το κύριο καθήκον της Αρχής Επαγγελματικών Προσόντων είναι η δημιουργία και λειτουργία ενός
«εθνικού συστήματος επαγγελματικών προσόντων» συμβατό με την Ευρωπαϊκή Ένωση. Το
Πιστοποιητικό Επαγγελματικής Ικανότητας ισχύει επίσης σε όλες τις χώρες της ΕΕ και σας
διευκολύνει να ασκήσετε το επάγγελμά σας σε αυτές . Επιπλέον, μπορείτε να υποβάλετε αίτηση και
να βρείτε εργασίες σε χώρες της ΕΕ όπου απαιτήται πιστοποιητικό επαγγελματικών προσόντων
μέσω του συστήματος Europass για αναζήτηση εργασίας .
Το συμπλήρωμα πιστοποιητικού Europass, το οποίο μπορείτε να ζητήσετε όταν λάβετε το
Πιστοποιητικό Επαγγελματικής Ικανότητας, είναι ένα πρόσθετο έγγραφο που μπορεί να δοθεί σε
όσους διαθέτουν πιστοποιητικό επαγγελματικών προσόντων. Χάρη σε αυτό το έγγραφο, έχετε την
ευκαιρία να αποδείξετε τα επαγγελματικά σας έγγραφα σε χώρες της ΕΕ.</p>
 <h2>17. ΤΙ ΕΙΝΑΙ ΤΟ ΣΥΜΠΛΗΡΩΜΑ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ EUROPASS</h2>
 <p>Συμπλήρωμα πιστοποιητικού Europass. Περιέχει λεπτομερείς πληροφορίες σχετικά με το
Πιστοποιητικό Επαγγελματικής Κατάρτισης MYK, το οποίο μπορεί να δοθεί στους κατόχους
πιστοποιητικού επαγγελματικής εκπαίδευσης και κατάρτισης ή πιστοποιητικού επαγγελματικής
ικανότητας MYK, χρησιμοποιείται σε χώρες της ΕΕ και χώρες που αναγνωρίζουν το Europass,
παρέχει πληροφορίες, δεξιότητες και ικανότητες προς κατανόηση πιο εύκολα, ειδικά από εργοδότες
ή ιδρύματα εκτός της χώρας, και επίσης είναι μια κοινή μορφή που περιλαμβάνει τα μαθησιακά
αποτελέσματα που σχετίζονται με τις μονάδες προσόντων που περιλαμβάνονται στο Πιστοποιητικό
Επαγγελματικών Προσόντων MYK.
Το Συμπλήρωμα Πιστοποιητικού Europass σάς βοηθά να περιγράφετε τα προσόντα επαγγελματικής
κατάρτισης και πρακτικής άσκησης με σαφή και συνεπή τρόπο.
Συμπλήρωμα πιστοποιητικού Europass.
Εξηγεί διεξοδικά τις επικυρωμένες αποκτηθείσες δεξιότητες και ικανότητες,
Παρέχει πληροφορίες σχετικά με το περιεχόμενο του Προγράμματος Πιστοποιητικών και τον τρόπο
απόκτησής του,
Διασφαλίζει ότι το πιστοποιητικό μπορεί να γίνει καλύτερα κατανοητό, να αξιολογηθεί και να
συγκριθεί σε εθνικό και διεθνές επίπεδο,
Στόχος του είναι να διευκολύνει την πρόσβαση ενός ατόμου σε ευκαιρίες εργασίας και
εκπαίδευσης.
Προσφέρεται στους κατόχους Πιστοποιητικού Επαγγελματικού Προσόντων MYK από την MYK/VQA.</p>
 <h2>18. ΤΙ ΠΕΡΙΛΑΜΒΑΝΕΤΑΙ ΣΤΟ ΣΥΜΠΛΗΡΩΜΑ ΠΙΣΤΟΠΟΙΗΤΙΚΟΥ MYK EUROPASS</h2>
 <p>Την ονομασία της πιστοποίησης VQA ( Vocational Qualification Authority ),
τις επικυρωμένες δεξιότητες και τις ικανότητες που έχει ο κάτοχος του πιστοποιητικού,
Τύποι επαγγελμάτων στα οποία μπορεί να εισέλθει ο κάτοχος πιστοποιητικού,

νομική βάση του πιστοποιητικού,
επίσημους τρόπους απόκτησης του πιστοποιητικού(μάθηση ή επαγγελματική εμπειρία)
τη ρύθμιση και τη διαπίστευση ιδρυμάτων ,
το επίπεδο του πιστοποιητικού εντός του πλαισίου προσόντων(εθνικό και ευρωπαϊκό πλαίσιο
προσόντων),
Απαιτήσεις εισόδου και ευκαιρίες μεταφοράς στο επόμενο επίπεδο εκπαίδευσης.</p>
 <h2>19. ΙΣΧΥΕΙ ΣΤΟ ΕΞΩΤΕΡΙΚΟ ΤΟ ΣΥΜΠΛΗΡΩΜΑ ΠΙΣΤΟΠΟΙΗΣΗΣ EUROPASS</h2>
 <p>Το Συμπλήρωμα Πιστοποιητικού Europass εξηγεί το πιστοποιητικό σε τρίτους (ιδιαίτερα άτομα σε
άλλη χώρα).
Χρησιμοποιείται μαζί με το κύριο πιστοποιητικό και δεν αντικαθιστά το κύριο πιστοποιητικό. Το
συμπλήρωμα πιστοποιητικού Europass δεν περιέχει τα στοιχεία ταυτότητας του κατόχου του
πιστοποιητικού (όνομα, τόπος-ημερομηνία γέννησης, κ.λπ.).
Δεν παρέχει άμεσο δικαίωμα επίσημης αναγνώρισης του αρχικού πιστοποιητικού σε άλλες χώρες.
Στοχεύει στη διευκόλυνση της ορθής αξιολόγησης του αρχικού πιστοποιητικού και της κατανόησης
και αναγνώρισής του από τις αρμόδιες αρχές.
Δεν είναι ένα έγγραφο που παρέχει αυτόματη αναγνώριση ή εγκυρότητα από μόνο του. Ωστόσο,
είναι ένα σημαντικό εργαλείο που θα πρέπει να χρησιμοποιείται κατά τη διενέργεια των
διαδικασιών αναγνώρισης/επικύρωσης/κατανόησης του αρχικού πιστοποιητικού στην Ευρώπη.</p>
      </span>
      </div>
    </div>
  )
}

function References(){
  return(
    <div id="References">
      <img id='TopImage' src={refSlider} alt="slider2" />
      <div id='SectionContainer'>
      
      <h1>ΟΙ ΑΝΑΦΟΡΕΣ ΜΑΣ</h1>
        <span>Η EUROPLUS παρέχει υπηρεσίες για Πιστοποιητικό Επαγγελματικής Ικανότητας, Πιστοποιητικό
Επαγγελματικής Κατάρτισης και Πιστοποίηση Προσωπικού .Ως κέντρο εξεταστικών υποψηφίων,
είμαστε περήφανοι που πιστοποιούμε υπαλλήλους .
Μπορείτε να συνεργαστείτε με την EUROPLUS, μια ποιοτική, αξιόπιστη, τεχνολογική και καινοτόμο
εταιρική εταιρεία, και να εξασφαλίσετε την εργασία σας και τους υπαλλήλους σας.
EUROPLUS: Είναι κέντρο συλλογής υποψηφίων, εγγραφής και εξέτασης υποψηφίων της Paksoy
Certification, ενός φορέα πιστοποίησης εξουσιοδοτημένος από τη MYK και την TÜRKAK.</span>
<h1 style={{textAlign:"center", padding:"30px"}}>Οι άδειες επαγγελματικών προσόντων μας</h1>
      <div className='CertLogos'>
          <a href="https://iaf.nu/en/member-details/?member_id=100"><img src={lic2} alt="licLogo" style={{height:"125px"}}/></a>
          <a href="https://www.myk.gov.tr/"><img src={lic1} alt="licLogo" style={{height:"125px"}}/></a>
          <a href="https://europa.eu/europass/tr/contact-us/ulusal-europass-merkezleri"><img src={lic3} alt="licLogo" style={{height:"125px"}}/></a>
      </div>
      <h1 style={{textAlign:"center", padding:"30px"}}>Οι διεθνείς μας διαπιστεύσεις</h1>
      <div className='CertLogos'>
        <a href="https://iaf.nu/en/about-iaf-mla/"><img src={acc1} alt="accLogo" style={{height:"100px"}}/></a>
        <a href="https://nac-us.org/accreditation-activities-of-nac"><img src={acc2} alt="accLogo" style={{height:"100px"}}/></a>
        <a href="https://nac-us.org/about"><img src={acc3} alt="accLogo" style={{height:"100px"}}/></a>
      </div>
      </div>
      
    </div>
  )  
}

function Slider(){
  let [sliderIndex,setSliderIndex] = useState(0)
  let slides = [slider,slider2,slider3,slider4,slider5,slider6,slider7,slider8,slider9,slider10,slider11,slider12,slider13,slider14]
  
  let interval = setInterval(() => {
    handleSlider(1)
    return clearInterval(interval)
  },5000)
  
  async function handleSlider(number){
    if(sliderIndex === slides.length -1 && number === 1){
      sliderIndex = 0
    }else if(sliderIndex === 0 && number === -1){
      sliderIndex = slides.length-1
    }else{
      sliderIndex += number
    }
    setSliderIndex(sliderIndex)
    return clearInterval(interval)
  }
  return(
    <div id='Slider'>
      <img src={slides[sliderIndex]} alt="sliderImage" />
      <button onClick={e => handleSlider(-1)}>&lt;</button>
      <button onClick={e => handleSlider(1)}>&gt;</button>
    </div>
  )
}

export default App;
